@import "../00-settings/colors.scss";
@import "../00-settings/fonts.scss";
@import "../00-settings/spacings.scss";
@import "../00-settings/responsive-breakpoints.scss";
@import "../01-tools/bootstrap/variables.scss";

.nos-link-list {
    &__pictogram {
        margin-right: $spacing-m;
        &-small {
            width: 15px;
            height: 15px;
        }
    }

    &__list {
        list-style: none;
        display: grid;
        grid-template-columns: 1fr;
        gap: $grid-gutter-width / 2;

        @media (min-width: $msv-breakpoint-sm) {
            grid-template-columns: 1fr 1fr;
        }

        @media (min-width: $msv-breakpoint-lg) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        margin-bottom: $spacing-m;
    }

    &__text {
        @include font-callout-s();
    }

    &__pictogram {
        svg {
            width: 60px;
            height: 60px;
        }
    }
}
