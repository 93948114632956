@import "../02-generic/button.scss";

.ms-button {
    &__primary-dark {
        @include primary-button-dark();
    }

    &__primary-light {
        @include primary-button-light();
    }

    &__secondary-dark {
        @include secondary-button-dark();
    }

    &__secondary-light {
        @include secondary-button-light();
    }
}
