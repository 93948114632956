@import '../02-generic/button.scss';
@import '../01-tools/bootstrap/variables.scss';

/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}

html {
    box-sizing: border-box;
	scroll-behavior: smooth;
    -ms-overflow-style: scrollbar;
}

body {
    font-family: $msv-primary-font-family;
    background: var(--msv-body-bg-color);
    color: $msv-text-color;
    font-size: $msv-font-size-m;
    line-height: $msv-line-height-m;
    min-height: 100vh;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

header {
    background: $msv-white;
}

/* Remove list styles on ul, ol elements with a class attribute */

/* Remove default padding */
ol[class],
ul[class] {
    padding: 0;
    list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

a:hover {
    text-decoration: underline;
}

a {
    @include link-regular-decoration();
    display: inline-flex;
}

/* Make images easier to work with */
img {
    max-width: 100%;
    display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
    margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

/* Tables */
table {
    border-collapse: collapse; // Prevent double borders
}

caption {
    padding-top: $table-cell-padding;
    padding-bottom: $table-cell-padding;
    color: $table-caption-color;
    text-align: left;
    caption-side: bottom;
}

// 1. Removes font-weight bold by inheriting
// 2. Matches default `<td>` alignment by inheriting `text-align`.
// 3. Fix alignment for Safari
th {
    font-weight: $table-th-font-weight; // 1
    text-align: inherit; // 2
    text-align: -webkit-match-parent; // 3
}

/* Remove all animations and transitions for people that prefer not to see them */

@media (prefers-reduced-motion: reduce) {
    * {
        /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
        animation-duration: 0.01ms !important;
        /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
        animation-iteration-count: 1 !important;
        /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
        transition-duration: 0.01ms !important;
        /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
        scroll-behavior: auto !important;
    }
}
