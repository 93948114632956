@import "../../00-settings/colors.scss";
@import "../../00-settings/spacings.scss";
@import "../../00-settings/fonts.scss";
@import "../../00-settings/responsive-breakpoints.scss";

// Extracted options and variables from Bootstrap

// Define only used/needed Bootstrap variables and options

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system
$white: $msv-white;
$black: $msv-black;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-rounded: true;
$enable-transitions: true;
$enable-prefers-reduced-motion-media-query: true;
$enable-grid-classes: true;
$enable-gradients: false;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
  xs: $msv-breakpoint-xs,
  sm: $msv-breakpoint-sm,
  md: $msv-breakpoint-md,
  lg: $msv-breakpoint-lg,
  xl: $msv-breakpoint-xl
);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
  sm: $msv-container-min-width-sm,
  md: $msv-container-min-width-md,
  lg: $msv-container-min-width-lg,
  xl: $msv-container-min-width-xl
);

@include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-columns: 12;
$grid-gutter-width: 30px;
$grid-row-columns: 6;

// TODO: Map to MSV design tokens
// Components
//
// Define common padding and border radius sizes and more.
$border-radius: 3px;
$border-radius-sm: 1px; // TODO: needed?
$border-radius-lg: 6px; // TODO: needed?

// Typography
//
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base: $font-family-sans-serif;
$font-weight-normal: 400;

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$line-height-base: 1.5;
$font-size-sm: $font-size-base * .875;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
$zindex-tooltip: 1070;

// Tooltips
$tooltip-font-size: $font-size-sm;
$tooltip-max-width: 200px;
$tooltip-color: $white;
$tooltip-bg: $msv-independent;
$tooltip-border-radius: $border-radius;
$tooltip-opacity: 1;
$tooltip-padding-y: .25rem;
$tooltip-padding-x: .5rem;
$tooltip-margin: 0;

$tooltip-arrow-width: .8rem;
$tooltip-arrow-height: .4rem;
$tooltip-arrow-color: $tooltip-bg;

// Alerts
//
// Define alert colors, border radius, and padding.
$alert-padding-y: $spacing-m;
$alert-padding-x: $spacing-m;
$alert-margin-bottom: $spacing-m;
$alert-border-radius: $border-radius;
$alert-link-font-weight: $msv-font-weight-bold;
$alert-border-width: 1px;

$alert-bg-level: -10;
$alert-border-level: -9;
$alert-color-level: 6;

// Close
$close-font-size: $msv-font-size-m;
$close-font-weight: $msv-font-weight-bold;
$close-color: $msv-black;
$close-text-shadow: $msv-depth8;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
$spacer: $spacing-m;

$spacers: ();
$spacers: map-merge(
  (
    s: $spacing-s,
    m: $spacing-m,
    l: $spacing-l,
    xl: $spacing-xl,
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8%;

$theme-colors: ();
$theme-colors: map-merge(
  (
    "primary": $msv-primary,
    "secondary": $msv-secondary,
    "success": $msv-success,
    "info": $msv-info,
    "warning": $msv-warning,
    "danger": $msv-danger,
    "light": $msv-light,
    "dark": $msv-dark,
  ),
  $theme-colors
);

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: $spacing-m;
$table-cell-padding-sm: $spacing-s;

$table-color: $msv-font-primary-color;
$table-bg: $msv-white;
$table-accent-bg: $msv-independent-10;
$table-hover-color: $table-color;
$table-hover-bg: rgba($msv-black, .075);
$table-active-bg: $table-hover-bg;

$table-border-width: 1px;
$table-border-color: $msv-independent-10;

$table-head-bg: $msv-white;
$table-head-color: $msv-font-primary-color;
$table-th-font-weight: $msv-font-weight-bold;

$table-dark-color: $msv-white;
$table-dark-bg: $msv-independent-50;
$table-dark-accent-bg: rgba($msv-white, .05);
$table-dark-hover-color: $table-dark-color;
$table-dark-hover-bg: rgba($msv-white, .075);
$table-dark-border-color: lighten($table-dark-bg, 7.5%);

$table-striped-order: odd;

$table-caption-color: $msv-font-primary-color;

$table-bg-level: -9;
$table-border-level: -6;

// Utilities
// Possible values: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex
$displays: flex;