@import "../00-settings/colors.scss";
@import "../00-settings/fonts.scss";
@import "../00-settings/spacings.scss";
@import "../00-settings/responsive-breakpoints.scss";

.ms-tab {
    &-header-section {
        display: flex;

        // Module heading types
        h1 {
            @include container-heading-h1();
        }

        h2 {
            @include container-heading-h2();
        }

        h3 {
            @include container-heading-h3();
        }

        h4 {
            @include container-heading-h4();
        }

        h5 {
            @include container-heading-h5();
        }

        h5 {
            @include container-heading-h6();
        }
    }

    &-tabs-section {
        display: flex;

        &-li {
            cursor: pointer;
            position: relative;
            padding: $spacing-m;
            margin-right: $spacing-m;
            border: 1px solid $msv-raisin;
            @include font-callout-s();

            // Active item
            &[aria-selected="true"] {
                color: $msv-white;
                background: $msv-raisin;
                border-color: $msv-raisin;
            }
        }

        li:last-of-type {
            margin-right: 0;
        }
    }

    &-content-section {
        margin-top: $spacing-l;
    }

    &-item-content__content {
        &[aria-hidden="true"] {
            display: none;
        }
    }
}
