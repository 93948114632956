@import "../00-settings/colors.scss";
@import "../00-settings/fonts.scss";

.ms-text-block {
    @include rich-text-styles();

    &.alignment__center {
        text-align: center;
    }

    &.alignment__left {
        text-align: left;
    }

    &.alignment__right {
        text-align: right;
    }
    
    // s
    &.fontsize__small {
        p {
            @include font-body-regular-s();
        }
    }

    // m
    &.fontsize__medium {
        p {
            @include font-body-regular-m();
        }
    }

    // l
    &.fontsize__large {
        p {
            @include font-body-regular-l();
        }
    }

    // xl, special use-case to be align with Sitebuilder dropdown
    &.fontsize__extralarge {
        p {
            font-family: $msv-font-family-roboto-regular;
            font-size: $msv-body-font-size-xl;
            line-height: $msv-body-line-height-xl;
        }
    }
}
