@import "bootstrap/scss/mixins/grid-framework";
@import "../00-settings/colors.scss";
@import "../01-tools/layout.scss";

// TODO:
// a.) Change CSS parent to a meaningfull root element, like .msc-container that can be applied on the main content container)
// b.) Replace with custom CSS class names added with Sitebuilder, like msv-container__header, msv-container__footer, msv-container__main, example: .msv-container__main .msc-container__default-container {}

// Emulatate Bootstrap's container* classes
main {

    // Applied with main container that holds all content in a row inside of Sitebuilder
    .default-container {
        @include make-container();

        &.grid-3x2>.row,
        &.grid-3x2 {
            @include media-breakpoint-up(lg) {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));

                .container-heading {
                    grid-column: 1 / span 3;
                }

                &>div[class^=col] {
                    &:nth-child(2) {
                        order: 2;
                    }

                    &:nth-child(3) {
                        order: 5;
                    }

                    &:nth-child(4) {
                        order: 3;
                    }

                    &:nth-child(5) {
                        order: 6;
                    }

                    &:nth-child(6) {
                        order: 4;
                    }

                    &:nth-child(7) {
                        order: 7;
                    }
                }
            }

            .nos-text-block {
                margin-top: 24px;
            }
        }

        &.grid-3x2.fluid {
            background: unset;
        }
    }

    // Module: "Container" layout: "Fill container"
    .container {
        @include msv-layout-container();
    }

    // Module "Container" layout: "Fill width"
    .fluid {
        width: 100%;
        background: $msv-bg-light-grey;
        @include msv-layout-content-block-padding-y();

        @include media-breakpoint-up(lg) {
            padding-bottom: 0px; // remove bottom padding for homepage
        }
    }

    // Module: "Container with 2 columns"
    // .default-container-2-slots {

    // }
}

// Use-Case breadcrumb in "Sub Header slot" 
header+div {
    .container {
        @include msv-layout-container();
    }
}

header .default-container>.row,
footer .default-container>.row {
    // Remove the negative margin from default .row, then the horizontal padding
    // from all immediate children columns (to prevent runaway style inheritance).
    // Based on https://github.com/twbs/bootstrap/blob/v4.6.2/scss/_grid.scss#L55
    margin-right: 0;
    margin-left: 0;

    >.col,
    >[class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}