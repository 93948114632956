@import "../00-settings/fonts.scss";

.nos-image {
    &__text {
        @include rich-text-styles();
    }

    .msc_image {
        // Part 1: Set a maximum relative to the parent
        max-width: 100%;
        // Part 2: Override the height to auto, otherwise images will be stretched
        // when setting a width and height attribute on the img element.
        height: auto;
    }
}