@import '../00-settings/colors.scss';
@import '../00-settings/fonts.scss';
@import '../00-settings/icons.scss';
@import '../00-settings/spacings.scss';

.nos-subscription-list {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;

    &__subscription-detail-item {
        @include spacer-y-m();
    }

    h1 {
        @include container-heading-h1();
    }

    td {
        white-space: nowrap;
    }

    &__tooltip-icon {
        margin-left: $spacing-s;
    }

    .msc-tooltip-inner p {
        margin-bottom: $spacing-m;
    }
}