@import "../00-settings/colors.scss";
@import "../00-settings/fonts.scss";
@import "../00-settings/icons.scss";
@import "../00-settings/responsive-breakpoints.scss";
@import "../01-tools/mixins.scss";
@import "../01-tools/layout.scss";
@import "../02-generic/button.scss";

.ms-footer {
    &__pre-container {
        @include msv-layout-container();
        margin-bottom: $msv-layout-container-padding-md;
        // Magic values, no fitting values in spacings list
        margin-top: 30px;

        @media (min-width: $msv-breakpoint-md) {
            margin-top: 100px;

        }

        @media (min-width: $msv-breakpoint-lg) {
            margin-top: 150px;
        }

        @media (max-width: $msv-breakpoint-lg - 1) {
            .ms-footer__list {
                margin-top: $spacing-m;
            }
        }

        .ms-footer__list[class*="ms-footer__category-"] {
            padding-left: calc(1rem + #{$spacing-s});
            margin-bottom: $spacing-m;
        }

        >.row {
            border-top: 1px solid $msv-independent-20;

            @media (min-width: $msv-breakpoint-lg) {
                border-bottom: 1px solid $msv-independent-20;
                padding-top: $msv-layout-container-padding-md;
                padding-bottom: $msv-layout-container-padding-md;
            }
        }

        @media (max-width: $msv-breakpoint-lg - 1) {
            max-width: 100%;
        }
    }

    &__main-container {
        display: none;

        @media (min-width: $msv-breakpoint-lg) {
            @include msv-layout-container();
            display: block;

            // Use-case: Footer Category headlines are linked
            .ms-footer__list-item {
                .ms-footer__list-item:first-child {                    
                    .ms-footer__link {
                        @include font-body-bold-s();
                    }
                }
            }
        }

    }

    &__post-container {
        @include spacer-y-m();
        background-color: $msv-independent;
        padding-top: 5px;
        padding-bottom: 5px;

        // Limit innner container to "content area"
        .container {
            @include msv-layout-container();
        }

        .ms-footer__list {
            align-items: center;

            .ms-footer__list {
                align-items: flex-start;
            }

            .ms-footer__list-item {
                flex-basis: min-content;
                width: auto;
            }

            .ms-footer-social-media {
                flex-direction: row;
            }
        }

        :is(.ms-footer__item, .ms-footer__link) {
            color: var(--msv-font-secondary-color);

            &:before {
                margin-right: $spacing-s;
            }
        }

        @media (min-width: $msv-breakpoint-lg) {
            margin-top: $msv-layout-container-padding-md;
        }
    }

    @media (min-width: $msv-breakpoint-lg) {
        &__mobile-container {
            display: none;
        }
    }

    &__heading {
        position: relative;
        line-height: $msv-line-height-m;
    }

    &__heading__title {
        @include font-body-bold-s();
        @include spacer-y-s();
    }

    &__heading__title-icon {
        @include spacer-y-s();
        position: absolute;
        right: calc(100% + #{$spacing-s});
    }

    &__link {
        @include font-body-regular-s();
        @include vfi();

        &:not(:hover) {
            text-decoration: none;
        }

        &:not(:empty) {
            @include spacer-y-s();
            line-height: $msv-line-height-m;
        }
    }

    &__item img {
        height: auto;
    }

    &__list {
        display: flex;
        flex-direction: column;
        line-height: 0;

        .ms-footer__list-item {
            flex: 1 0 auto;
        }

        @media (min-width: $msv-breakpoint-lg) {
            flex-direction: row;

            .ms-footer__list {
                flex-direction: column;
            }

            .ms-footer__list-item {
                width: clamp(100px, 17%, 200px);

                &:not(:last-child) {
                    margin-right: $spacing-m;
                }

                .ms-footer__list-item {
                    width: auto;
                }
            }
        }

    }
}

.ms-footer__category-circleUser .ms-footer__heading__title-icon {
    @include add-icon-custom("circleUser");
}

.ms-footer__category-envelope .ms-footer__heading__title-icon {
    @include add-icon-custom("envelope");
}

.ms-footer__category-comment .ms-footer__heading__title-icon {
    @include add-icon-custom("comment");
}

.ms-back-to-top {
    @include card-button-with-icon-only();
    @include add-icon-custom("chevronUp");
    bottom: $msv-layout-padding-x-xl;
    right: $msv-icon-size-xs;
    position: fixed;
}