@import "../00-settings/fonts.scss";
@import "../00-settings/spacings.scss";
@import "../01-tools/bootstrap/variables.scss";

// Compensate "default" row HTML element
h1,
h2,
h3,
h4,
h5,
h6 {
    &.heading {
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;
        width: 100%;
    }
}

// Only "page heading "should not be centered
h1 {
    &.heading {
        @include container-heading-h1();
    }
}

h2 {
    &.heading {
        @include container-heading-h2();
    }
}

h3 {
    &.heading {
        @include container-heading-h3();
    }
}

h4 {
    &.heading {
        @include container-heading-h4();
    }
}

h5 {
    &.heading {
        @include container-heading-h5();
    }
}

h6 {
    &.heading {
        @include container-heading-h6();
    }
}