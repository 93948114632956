// Must be synchronised with values inside nos.theme.settings.json
$msv-breakpoint-xs: 0;
$msv-breakpoint-sm: 576px;
$msv-breakpoint-md: 768px;
$msv-breakpoint-lg: 992px;
$msv-breakpoint-xl: 1200px;

// TODO: Remove from here and from module(s)
$msv-breakpoint-xxl: 1800px;

$msv-container-min-width-xs: 100%;
$msv-container-min-width-sm: 540px;
$msv-container-min-width-md: 720px;
$msv-container-min-width-lg: 960px;
$msv-container-min-width-xl: 1290px;

$msv-width-full: 100%;
$msv-width-half: 50%;
$msv-width-threequarter: 75%;
$msv-width-quarter: 25%;
$msv-height-full: 100%;
