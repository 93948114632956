@import "../00-settings/colors.scss";
@import "../00-settings/fonts.scss";
@import "../00-settings/spacings.scss";
@import "../00-settings/icons.scss";
@import "../00-settings/responsive-breakpoints.scss";
@import "../02-generic/button.scss";

$msc-cart-btn-addcarttotemplate-padding: $spacing-s $spacing-l;
$msc-cart-btn-addcarttotemplate-margin-right: $spacing-s;
$msc-invoice-summary-heading-padding: $spacing-m $spacing-l;
$msc-invoice-summary-items-padding: 0 $spacing-l $spacing-l;
$msc-invoice-summary-line-invoice-margin-top: $spacing-m;
$msc-invoice-summary-line-total-margin-top: $spacing-l;
$msc-invoice-summary-line-total-padding-top: $spacing-l;
$msc-invoice-order-summary-checkout-margin-bottom: $spacing-l;
$msc-invoice-order-summary-checkout-padding-top: $spacing-s;
$msc-invoice-order-summary-line-items-padding: $spacing-m $spacing-m 0;
$msc-invoice-order-summary-tax-amount-margin-bottom: $spacing-m;

.msc-order-summary {
    @media screen and (max-width: $msv-breakpoint-sm) {
        margin: unset;
    }

    &__heading {
        @include font-heading-h5();
        padding: 0 $spacing-m;
        border-bottom: 1px solid $msv-gray-50;
        color: $msv-font-primary-color;
    }

    &-wrapper > .msc-order-summary__items {
        height: fit-content;
        display: flex;
        flex: 0 0 368px;
        flex-direction: column;
        margin: 0 auto;

        .msc-alert {
            @include font-body-regular-s();
            color: $msv-red-10;
            padding: 27px 35px 37px;
        }

        .msc-order-summary__label {
            text-transform: capitalize;
        }
    }

    &__line {
        &-sub-total,
        &-loyalty,
        &-shipping,
        &-other-charges {
            @include font-body-regular-m();
            padding: $spacing-m;
        }

        &-tax-amount {
            @include font-body-regular-m();
			padding-left: $spacing-m;
			padding-right: $spacing-m;
            margin-bottom: $msc-invoice-order-summary-tax-amount-margin-bottom;
        }
    }

    &__line-total-discounts {
        @include font-body-regular-m();
        padding: $spacing-l $spacing-l 0;
    }

    &__line-total {
        @include font-heading-h5();
        padding: $spacing-l 0 $spacing-l 0;
        border-top: 1px solid $msv-gray-50;
        margin: 0 $spacing-m;
    }

    &__checkout {
        margin-top: $spacing-l;
        margin-bottom: $spacing-l;

        a:visited,
        a:hover,
        a:link,
        a:active {
            text-decoration: none;
        }

        .msc-cart__btn {
            &-checkout,
            &-guestcheckout,
            &-backtoshopping {
                @include secondary-button-dark();
                margin: 14px 35px 5px;
                width: calc(100% - 65px);
            }

            &-checkout {
                @include primary-button-dark();
                text-align: center;
            }

            &-addcarttotemplate {
                margin: 14px auto 5px;
                border: none;

                @include add-icon($msv-order-template-add, before, $msv-outline-icon-weight);
                @include icon-button-light();
                padding: $msc-cart-btn-addcarttotemplate-padding;

                &::before {
                    margin-right: $msc-cart-btn-addcarttotemplate-margin-right;
                    vertical-align: middle;
                    font-size: $msv-font-size-ml;
                }

                &:not(:disabled) {
                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            &-backtoshopping {
                position: absolute;
                top: 10px;
                margin: 0;
                right: 2px;
            }
        }
    }

    &__value {
        float: right;
    }

    &-wrapper {
        .msc-order-summary__checkout {
            .msc-alert-danger {
                @include font-body-regular-s();
                color: $msv-red-10;
                display: flex;
                justify-content: flex-end;
                margin-right: 35px;
                margin-bottom: 10px;
            }
        }
    }
}

.msc-invoice-summary-wrapper {
    border: 0.5px solid var(--msv-checkout-order-summary-border-color);
    flex: 0 0 30%;

    .msc-invoice-summary__heading {
        @include font-heading-h5();

        padding: $msc-invoice-summary-heading-padding;
        border-bottom: 0.5px solid $msv-gray-50;
    }

    .msc-invoice-summary__items {
        padding: $msc-invoice-summary-items-padding;
    }

    .msc-invoice-summary__line-invoice {
        @include font-body-regular-m();
        margin-top: $msc-invoice-summary-line-invoice-margin-top;
        display: flex;
        justify-content: space-between;
    }

    .msc-invoice-summary__line-total {
        @include font-heading-h5();
        display: flex;
        justify-content: space-between;
        border-top: 0.5px solid $msv-gray-50;
        margin-top: $msc-invoice-summary-line-total-margin-top;
        padding-top: $msc-invoice-summary-line-total-padding-top;

        // @media (max-width: $msv-breakpoint-md - 1) {
        // }

        // @media (min-width: $msv-breakpoint-md) and (max-width: $msv-breakpoint-lg - 1) {
        // }
    }

    .msc-order-summary__checkout {
        border: none;
        border-top: 0.5px solid var(--msv-checkout-order-summary-border-color);
        border-radius: initial;
        margin-top: 0;
        padding-bottom: 0;
        margin-bottom: $msc-invoice-order-summary-checkout-margin-bottom;
        padding-top: $msc-invoice-order-summary-checkout-padding-top;
    }
}

@media (max-width: $msv-breakpoint-md) {
    .msc-order-summary-wrapper {
        .msc-order-summary__checkout {
            .msc-cart__btn-backtoshopping {
                display: none;
            }

            .msc-alert-danger {
                margin-left: 12px;
                justify-content: flex-start;
                margin-right: 12px;
            }

            .msc-cart__btn-checkout,
            .msc-cart__btn-guestcheckout {
                width: calc(100% - 24px);
            }
        }
    }
}

@media (min-width: $msv-breakpoint-md) and (max-width: $msv-breakpoint-lg) {
    .msc-order-summary-wrapper {
        .msc-order-summary__checkout {
            .msc-cart__btn-backtoshopping {
                letter-spacing: 0.1em;
                top: 35px;
            }

            .msc-alert-danger {
                margin-left: $spacing-m;
                justify-content: flex-start;
                margin-right: $spacing-m;
            }
        }
    }
}

@media screen and (max-width: $msv-breakpoint-lg) {
    .msc-invoice-summary-wrapper {
        margin: 0;
        flex: 100%;

        .msc-invoice-summary {
            &__heading,
            &__items {
                padding-left: $spacing-m;
                padding-right: $spacing-m;
            }
        }

        &__checkout {
            .msc-cart__btn-checkout,
            .msc-cart__btn-guestcheckout {
                display: inline-flex;
                margin-left: $spacing-m;
                margin-right: $spacing-m;
            }
        }
    }

    .msc-order-summary-wrapper {
        margin: 0;
        flex: 100%;

        .msc-promo-code__line-container {
            margin-right: $spacing-m;
        }

        .msc-order-summary {
            &__heading,
            &__line-sub-total,
            &__line-loyalty,
            &__line-shipping,
            &__line-other-charges,
            &__line-tax-amount,
            &__line-total-discounts {
                padding-left: $spacing-m;
                padding-right: $spacing-m;
            }

            &__line-total {
                margin: 0 $spacing-s;
                padding-left: 0;
                padding-right: 0;
            }

            &__checkout {
                .msc-cart__btn-checkout,
                .msc-cart__btn-guestcheckout {
                    display: inline-flex;
                    margin-left: 12px;
                    margin-right: 12px;
                }

                .msc-cart__btn-guestcheckout {
                    margin-right: 12px;
                }

                .msc-promo-code-heading,
                .msc-promo-code__group {
                    padding-left: $spacing-m;
                    padding-right: $spacing-m;
                }
            }
        }

        > .msc-order-summary__items .msc-alert {
            padding-left: $spacing-m;
            padding-right: $spacing-m;
        }
    }
}
