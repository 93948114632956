@import "../00-settings/colors.scss";
@import "../00-settings/spacings.scss";
@import "../02-generic/form.scss";
@import "../01-tools/bootstrap/variables.scss";

.ms-form {
    &__group-horizontal {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        flex-wrap: wrap;
        margin-right: -$grid-gutter-width * .5;
        margin-left: -$grid-gutter-width * .5;

        > .col,
        > [class*="col-"] {
          padding-right: $grid-gutter-width * .5;
          padding-left: $grid-gutter-width * .5;
        }
    }

    &__group-vertical {
        display: flex;
        flex-direction: column;
    }

    &__label {
        @include form-input-label();
    }

    &__label-checkbox {
        @include form-input-label-checkbox();
        margin-left: $spacing-s;
    }

	&__label-radio {
        @include form-input-label-radio();
        margin-left: $spacing-s;
    }

    &__input {
        @include form-input-box();
    }

    &__input-checkbox {
        @include form-input-checkbox();
    }

    &__input-radio {
        @include form-input-radio();
    }

    &__dropdown {
        @include form-dropdown();
    }

    // &__textarea {
    //     @include form-textarea(); // TODO
    // }
}
