@import "../00-settings/fonts.scss";
@import "../00-settings/icons.scss";
@import "../02-generic/button.scss";
@import "../02-generic/form.scss";

$msv-order-template-add-line-search-button-width-height:10px;

.msc-promo-code {
    &-heading {
        @include font-heading-h5();        
        padding: $spacing-m;
    }

    &__form-container ~ .msc-alert-danger {
        @include font-body-regular-s();
        padding: $spacing-m;
        color: var(--msv-buybox-msc-alert-color);
        @include add-icon($msv-information);
        &::before {
            font-size: 20px;
            padding-right: $spacing-s;
            top: 2px;
            position: relative;
            vertical-align: sub;
        }
    }

    &__group {
        padding: $spacing-m;
        justify-content: space-between;
        display: flex;
    }

    &__apply-btn {
        @include primary-button-dark();
        @include add-icon($msv-Search);
        margin-left: 10px;
        width: 44px;
        height: 44px;
        border-radius: 3px;
        min-width: unset;
        padding: 0;
        cursor: pointer;

        &::before {
            font-size: $msv-icon-size-m;
            vertical-align: middle;
        }

        &:focus::before {
            content: $msv-Search;
            position: static;
            border-radius: unset;
            padding: 12px;
        }
    }

    &__input-box {
        @include form-input-box();
        text-transform: none;
        color: $msv-font-primary-color;
        padding: 14px 0 14px 9px;
        border: 0.5px solid $msv-gray-50;
        border-radius: 3px;
        width: calc(100% - 62px);
        flex-grow: 1;
        margin-bottom: 0;
    }

    &__discount {
        @include font-body-regular-s();
        color: $msv-font-primary-color;
        display: flex;
        justify-content: flex-end;
        margin-right: 35px;
        margin-bottom: 10px;
    }

    &__line-container {
        @include font-body-regular-s();
        color: $msv-green;
        padding: $spacing-m;
        display: flex;
    }

    &__line {
        &-value {
            display: flex;

            &-code {
                font-weight: 700;
                margin: 0 5px;
            }

            @include add-icon($msv-Checkbox-Circle-Checked);

            &::before {
                margin-right: 7px;
                font-size: 22px;
            }
        }

        &-discount-value {
            font-weight: $msv-font-weight-normal;
        }

        &__btn-remove {
            @include add-icon($msv-Cancel);
            background: transparent;
            border: none;
            cursor: pointer;
            color: $msv-text-color;
            margin-left: 10px;
        }
    }

    &__discount-heading {
        display: none;
    }
}
