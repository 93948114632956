// TODO: needed? remove?
@import "../00-settings/colors.scss";
@import "../00-settings/fonts.scss";

:root {
    // Background
    --msv-bg-color: #{$msv-white};
    --msv-body-bg-color: #{$msv-white};

    // Brand accents
    --msv-accent-brand-color: #{$msv-primary};
    --msv-accent-brand-alt-color: #{$msv-primary-hover};
    --msv-accent-secondary-btn: #{$msv-secondary-hover};
    --msv-font-red-background-color: #{$msv-red-10};

    // Font
    --msv-font-primary-color: #{$msv-raisin};
    --msv-font-secondary-color: #{$msv-white};
    --msv-font-disabled-color: #{$msv-gray-50};

    --msv-font-red-color: #{$msv-red};

    // Body
    --msv-body-font-color: #{$msv-raisin};
    --msv-body-font-size-xs: #{$msv-font-size-xs};
    --msv-body-font-size-s: #{$msv-font-size-s};
    --msv-body-font-size-m: #{$msv-font-size-m};
    --msv-body-font-size-l: #{$msv-font-size-l};
    --msv-body-font-size-xl: #{$msv-font-size-xl};
    --msv-body-font-size-xxl: #{$msv-font-size-xxl};
    --msv-body-line-height-xs: #{$msv-line-height-xs};
    --msv-body-line-height-s: #{$msv-line-height-s};
    --msv-body-line-height-m: #{$msv-line-height-m};

    // Weight
    --msv-font-weight-bold: #{$msv-font-weight-bold};
    --msv-font-weight-normal: #{$msv-font-weight-normal};

    // Events
    --msv-button-primary-color: #{$msv-primary};
    --msv-button-primary-disabled-color: #{$msv-gray-50};
    --msv-button-secondary-color: #{$msv-white};
    --msv-button-secondary-disabled-color: #{$msv-white};

    // Modal
    --msv-modal-bg-color: #{$msv-white};
    --msv-modal-font-color: var(--msv-font-primary-color);
    --msc-modal-success-image-bg: #{$msv-gray-300};
    --msv-modal-success-alert-font-color: var(--msv-font-primary-color);
    --msv-modal-success-alert-border: #{$msv-gray-900};
    --msv-modal-font-size: var(--msv-body-font-size-l);

    // Buttons
    // Background
    --msv-secondary-button-background-color: #{$msv-white};

    // Primary button --LIGHT THEME
    --msv-primary-btn-light-font-color: #{$msv-white};
    --msv-primary-btn-light-bg-color: #{$msv-portland};
    --msv-primary-btn-light-border-color: #{$msv-portland};
    --msv-primary-btn-light-hover-bg-color: #{$msv-portland-hover};
    --msv-primary-btn-light-focus-bg-color: #{$msv-portland-70};
    --msv-primary-btn-light-focus-border-color: #{$msv-portland-hover};
    --msv-primary-btn-light-pressed-bg-color: #{$msv-portland-70};
    --msv-primary-btn-light-disabled-bg-color: #{$msv-portland-50};

    // Secondary button --LIGHT THEME
    --msv-secondary-btn-light-font-color: #{$msv-raisin};
    --msv-secondary-btn-light-bg-color: #{$msv-white};
    --msv-secondary-btn-light-border-color: #{$msv-raisin};
    --msv-secondary-btn-light-hover-bg-color: #{$msv-white-20};
    --msv-secondary-btn-light-focus-bg-color: #{$msv-white-20};
    --msv-secondary-btn-light-focus-border-color: #{$msv-blue};
    --msv-secondary-btn-light-pressed-bg-color: #{$msv-white-10};
    --msv-secondary-btn-light-disabled-bg-color: #{$msv-gray-50};
    --msv-secondary-btn-light-disabled-font-color: #{$msv-white};

    // Primary button --DARK THEME
    --msv-primary-btn-dark-font-color: #{$msv-portland};
    --msv-primary-btn-dark-bg-color: #{$msv-white};
    --msv-primary-btn-dark-border-color: #{$msv-portland};
    --msv-primary-btn-dark-hover-bg-color: #{$msv-gray-20};
    --msv-primary-btn-dark-focus-bg-color: #{$msv-portland-20};
    --msv-primary-btn-dark-focus-border-color: #{$msv-portland};
    --msv-primary-btn-dark-pressed-bg-color: #{$msv-white-10};
    --msv-primary-btn-dark-disabled-bg-color: #{$msv-gray-50};
    --msv-primary-btn-dark-disabled-font-color: #{$msv-gray-40};

    // Secondary button --DARK THEME
    --msv-secondary-btn-dark-font-color: #{$msv-white};
    --msv-secondary-btn-dark-bg-color: #{$msv-raisin};
    --msv-secondary-btn-dark-border-color: #{$msv-raisin};
    --msv-secondary-btn-dark-hover-bg-color: #{$msv-raisin-70};
    --msv-secondary-btn-dark-focus-bg-color: #{$msv-raisin-70};
    --msv-secondary-btn-dark-focus-border-color: #{$msv-raisin-70};
    --msv-secondary-btn-dark-pressed-bg-color: #{$msv-raisin-50};
    --msv-secondary-btn-dark-disabled-bg-color: #{$msv-gray-50};
    --msv-secondary-btn-dark-disabled-font-color: #{$msv-gray-40};

    // Feature Link light
    --msv-feature-link-light-font-color: #{$msv-gray-20};
    --msv-feature-link-light-disabled-font-color: #{$msv-gray-50};
    --msv-feature-link-light-underline-bg-color: #{$msv-portland};
    --msv-feature-link-light-border-color: #{$msv-blue};
    --msv-feature-link-light-focus-underline-bg-color: #{$msv-portland};
    --msv-feature-link-light-hover-underline-bg-color: #{$msv-portland};
    --msv-feature-link-light-active-underline-bg-color: #{$msv-independent-70};
    --msv-feature-link-light-disabled-underline-bg-color: #{$msv-gray-50};

    // Feature Link dark
    --msv-feature-link-dark-font-color: #{$msv-white};
    --msv-feature-link-dark-disabled-font-color: #{$msv-gray-40};
    --msv-feature-link-dark-underline-bg-color: #{$msv-portland};
    --msv-feature-link-dark-border-color: #{$msv-blue};
    --msv-feature-link-dark-focus-underline-bg-color: #{$msv-blue-90};
    --msv-feature-link-dark-hover-underline-bg-color: #{$msv-blue-90};
    --msv-feature-link-dark-active-underline-bg-color: #{$msv-blue-100};
    --msv-feature-link-dark-disabled-underline-bg-color: #{$msv-gray-40};

    // Links
    --msv-link-color: #{$msv-link-color};
    --msv-link-color-hover: #{$msv-link-color-hover};
    --msv-link-color-active: #{$msv-link-color-active};
    --msv-link-color-visited: #{$msv-link-color-visited};

    // Card buttons
    --msv-card-button-bg-color: #{$msv-black};
    --msv-card-button-font-color: #{$msv-white};
    --msv-card-button-disabled-font-color: #{$msv-gray-40};
    --msv-card-button-focus-border-color: #{$msv-portland};

    // Icon buttons
    --msv-icon-button-light-font-color: #{$msv-gray-20};
    --msv-icon-button-light-bg-color: #{$msv-transparent};
    --msv-icon-button-dark-font-color: #{$msv-white};
    --msv-icon-button-dark-bg-color: #{$msv-transparent};
    --msv-icon-button-light-focus-border-color: #{$msv-blue};
    --msv-icon-button-dark-focus-border-color: #{$msv-portland};

    // Toggle buttons
    --msv-toggle-button-color: #{$msv-blue};
    --msv-toggle-button-disabled-color: #{$msv-gray-50};
    --msv-toggle-button-focus-border-color: #{$msv-blue};

    // Forms
    --msv-form-control-box-color: #{$msv-blue};
    --msv-form-error-font-color: #{$msv-red};
    --msv-form-dropdown-border-color: #{$msv-gray-50};
    --msv-form-dropdown-focus-border-color: #{$msv-blue-50};
    --msv-form-dropdown-font-color: var(--msv-font-primary-color);
    --msv-form-dropdown-bg-color: var(--msv-bg-color);

    // Controls
    // Carousel flippers
    --msv-carousel-flipper-rest-bg-color: transparent;
    --msv-carousel-flipper-hover-bg-color: #{$msv-white};
    --msv-carousel-flipper-focus-bg-color: #{$msv-white};
    --msv-carousel-flipper-active-bg-color: #{$msv-white-20};
    --msv-carousel-flipper-border-color: #{$msv-blue};

    // Quantity flippers
    --msv-quantity-controls-disable-color: #{$msv-gray-50};
    --msv-quantity-control-disable-background-color: #{$msv-white};
    --msv-quantity-border-color: #{$msv-gray-50};
    --msv-quantity-btn-font-color: var(--msv-font-primary-color);
    --msv-quantity-controls-content-bg-color: #{$msv-white};
}
