@import "../../../00-settings/fonts.scss";
@import "../../../00-settings/icons.scss";
@import "../../../02-generic/button.scss";

$msv-duplicate-items-when-adding-to-order-template-dialog-cancel-button-margin: 0 20px;
$msv-duplicate-items-when-adding-to-order-template-dialog-footer-margin-top: 20px;
$msv-duplicate-items-when-adding-to-order-template-dialog-footer-margin-m: 20px 0 12px;

.msc-modal {
    .msc-duplicate-items-when-adding-to-order-template-dialog {
        &__dialog {
            &__content {
                display: flex;
                flex-wrap: wrap;
            }

            &__description {
                @include font-body-regular-m();
            }

            &__header {
                .msc-modal__title {
                    @include font-heading-h5();
                    @include add-icon($msv-information);
                    display: flex;
                    align-items: center;

                    &::before {
                        margin-right: 10px;
                    }

                    // @media (max-width: $msv-breakpoint-md) {
                    // }

                    // @media (min-width: $msv-breakpoint-md) and (max-width: $msv-breakpoint-lg) {
                    // }
                }
            }

            &__footer {
                margin-top: $msv-duplicate-items-when-adding-to-order-template-dialog-footer-margin-top;
            }

            &__update-quantity-button {
                @include primary-button-dark();
            }

            &__cancel-button {
                @include secondary-button-dark();
                margin: $msv-duplicate-items-when-adding-to-order-template-dialog-cancel-button-margin;
            }

            @media screen and (max-width: $msv-breakpoint-md) {
                &__footer {
                    button {
                        width: 100%;
                        margin: $msv-duplicate-items-when-adding-to-order-template-dialog-footer-margin-m;
                    }
                }
            }
        }
    }
}
