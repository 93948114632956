// Import needed/used Bootstrap parts
@import "functions";
@import "variables";
@import "mixins";

@import "bootstrap/scss/mixins/grid-framework";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";

@import "bootstrap/scss/utilities/align";
@import "bootstrap/scss/utilities/display";
@import "bootstrap/scss/utilities/flex";
@import "bootstrap/scss/utilities/spacing";
