// Extracted fuctions from Bootstrap

// Define only used/needed Bootstrap functions
// Ascending
// Used to evaluate Sass maps like our grid breakpoints.
@mixin _assert-ascending($map, $map-name) {
    $prev-key: null;
    $prev-num: null;
    @each $key, $num in $map {
      @if $prev-num == null or unit($num) == "%" or unit($prev-num) == "%" {
        // Do nothing
      } @else if not comparable($prev-num, $num) {
        @warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
      } @else if $prev-num >= $num {
        @warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
      }
      $prev-key: $key;
      $prev-num: $num;
    }
  }
  
  // Starts at zero
  // Used to ensure the min-width of the lowest breakpoint starts at 0.
  @mixin _assert-starts-at-zero($map, $map-name: "$grid-breakpoints") {
    @if length($map) > 0 {
      $values: map-values($map);
      $first-value: nth($values, 1);
      @if $first-value != 0 {
        @warn "First breakpoint in #{$map-name} must start at 0, but starts at #{$first-value}.";
      }
    }
  }

  @function theme-color($key: "primary") {
    @return map-get($theme-colors, $key);
  }

  // Request a theme color level
  @function theme-color-level($color-name: "primary", $level: 0) {
    $color: theme-color($color-name);
    $color-base: if($level > 0, $black, $white);
    $level: abs($level);

    @return mix($color-base, $color, $level * $theme-color-interval);
  }