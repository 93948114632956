
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.slider__thumb__range.sr-only {
    /* stylelint-disable declaration-no-important -- To override existing no important. */
    height: 8px !important;
    width: 8px !important;
    top: -4px;
}
