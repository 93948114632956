@import "../00-settings/colors.scss";
@import "../00-settings/fonts.scss";
@import "../00-settings/icons.scss";
@import "../00-settings/responsive-breakpoints.scss";
@import "../00-settings/spacings.scss";
@import "../01-tools/mixins.scss";
@import "../02-generic/button.scss";
@import "../02-generic/form.scss";

$msv-business-sign-up-message-margin-top: 20px;
$msv-business-sign-up-message-padding-bottom: 20px;
$msv-business-sign-up-button-margin-top: 20px;
$msv-business-sign-up-button-margin-spacing: 20px;
$msv-business-sign-up-disclaimer-margin-top: 20px;
$msv-business-sign-up-input-spacing: 20px;
$msv-business-sign-up-text-area-height: 164px;
$msv-address-dropdown-input-height: 52px;
$msv-address-dropdown-input-padding: 16px;

//style presets
:root {
    // heading
    --msv-business-sign-up-heading-font-color: var(--msv-font-primary-color);

    // alert
    --msv-business-sign-up-error-alert-bg: var(--msv-error-color);
    --msv-business-sign-up-error-alert-font-color: var(--msv-font-secondary-color);
    --msv-business-sign-up-error-alert-border: var(--msv-error-color);
    --msv-business-sign-up-error-color: var(--msv-error-color);
}

.nos-business-sign-up {
    &__page-heading {
        @include font-heading-h2();
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
    }

    &__form-item {
        &-CompanyAddress {
            margin-top: 0;
        }
        &-input {
            &-area {
                @include form-input-box();
                height: $msv-business-sign-up-text-area-height;
            }
        }

        .msc-address-form {
            &__item-isprimary {
                display: flex;

                .msc-address-form__label {
                    margin-top: auto;
                    margin-bottom: auto;
                    margin-right: 10px;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .msc-address-form__input-checkbox {
                    width: 20px;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            &__item-county {
                display: none;
            }

            &__input-text {
                padding: 6px 8px;
            }

            .MicrosoftMap {
                position: relative;
                width: 100%;

                .as_container_search {
                    width: 100%;
                }
            }

            &__alert {
                display: flex;
                order: 3;
            }

            &__button-save {
                @include primary-button-dark();
            }

            &__button-cancel {
                @include secondary-button-dark();
            }

            .address-form__item-invalid {
                .msc-address-form__alert {
                    @include font-body-regular-s();
                    text-transform: none;
                    border-radius: $msv-alert-border-radius;
                    position: relative;
                    margin-top: 15px;

                    @include add-icon($msv-times-circle, $icon-weight: $msv-outline-icon-weight);
                    color: var(--msv-address-form-error-font-color);

                    + input {
                        border-left: 4px solid var(--msv-address-form-error-border-color);
                    }

                    &::before {
                        margin-right: $msv-alert-icon-margin;
                        font-size: $msv-font-size-ml;
                    }
                }
            }
        }

        &-suggestions-list {
            z-index: 1001;
            border: 1px solid $msv-gray-50;
            background: $msv-white;
            list-style: none;

            li {
                padding: 8px;
                cursor: pointer;

                &:hover {
                    background-color: $msv-secondary-hover;
                }
            }
        }
    }

    &__page-error {
        @include alert();
        display: flex;
        margin-top: $msv-business-sign-up-message-margin-top;
        padding-bottom: $msv-business-sign-up-message-padding-bottom;
        width: 100%;

        @media screen and (min-width: $msv-breakpoint-md) {
            align-items: flex-end;
        }
    }

    &__item-error {
        color: var(--msv-business-sign-up-error-color);
    }

    &__cancel-button {
        display: none;
    }

    &__disclaimer {
        margin-top: $msv-business-sign-up-disclaimer-margin-top;
        width: 100%;

        a {
            text-decoration: underline;
        }
    }

    @media only screen and (max-width: $msv-breakpoint-lg) {
        .msc-address-form__item-threeletterisoregionname {
            display: block;
            margin-bottom: 10px;
            width: 100%;
        }

        .msc-address-form__item-phone {
            display: block;
            width: 100%;
        }

        &__form-item {
            .msc-address-form {
                &__item {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        &__cancel-button {
            display: none;
        }
    }
   
    &__section-square {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: $spacing-l 0 $spacing-m;

        &-number {
            font-size: 25px;
            font-weight: bold;
            height: 5vh;
            width: 5vh;
            color: $msv-white;
            background-color: $msv-independent;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: $spacing-m;
        }

        &-heading {
            font-weight: normal;
        }
    }

    &__section-heading {
        @include font-heading-h3();
    }
    
    .necessary {
        box-shadow: 4px 4px 10px var(--msv-font-red-color) !important;
    }
    
    .button-fake-disabled {
        border-color: var(--msv-button-primary-disabled-color);
        background-color: var(--msv-button-primary-disabled-color);
        color: var(--msv-font-secondary-color);
    }
}