// Extracted mixins from Bootstrap

// Define only used/needed Bootstrap mixins

// Vendor
@import "bootstrap/scss/vendor/rfs";

// Utilities
@import "bootstrap/scss/mixins/breakpoints";
@import "bootstrap/scss/mixins/hover";
@import "bootstrap/scss/mixins/reset-text";

// Skins
@import "bootstrap/scss/mixins/border-radius";
@import "bootstrap/scss/mixins/transition";

// Layout
@import "bootstrap/scss/mixins/grid-framework";
@import "bootstrap/scss/mixins/grid";

// Components
@import "bootstrap/scss/mixins/table-row";
