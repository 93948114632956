@import '../00-settings/colors.scss';
@import '../00-settings/fonts.scss';
@import '../00-settings/icons.scss';
@import '../00-settings/responsive-breakpoints.scss';
@import '../01-tools/mixins.scss';

//Variables
$site-picker-list-item-img-padding: 10px 10px 1px 0;
$site-picker-list-margin: 5px;
$site-picker-list-item-padding-top: 10px;
$site-picker-list-item-padding-bottom: 10px;
$site-picker-list-item-padding-left: 10px;
$site-picker-list-item-padding-right: 10px;
$site-picker-line-height: 40px;
$site-picker-height: 48px;
$site-picker-transparent: transparent;

//style presets
:root {
    --ms-site-picker-font-size: var(--msv-body-font-size-m);
    --ms-site-picker-gray-500: #{$msv-gray-500};
    --ms-site-picker-bg-color: #{$site-picker-transparent};
    --ms-site-picker-desktop-list-bg-color: #{$msv-white};
    --ms-site-picker-mobile-list-bg-color: #{$msv-white};
    --ms-site-picker-font-color: #{$msv-black};
}

.ms-site-picker {
    margin-left: 15px;
    align-self: flex-start;
    align-items: self-end;
    font-size: var(--ms-site-picker-font-size);
    height: $site-picker-height;

    &__icon {
        &::before {
            @include msv-icon();
            content: $msv-location; //TODO: Pick right icon
            text-align: center;
            transform: scaleX(-1);
            font-size: var(--msv-header-icon-font-size);
        }
    }

    &__button {
        @include font-body-regular-m();
        background-color: var(--ms-site-picker-bg-color);
        border: none;
        color: var(--msv-header-font-color);
        border: 1px dashed transparent;
        cursor: pointer;
        height: 100%;
        width: 100%;
        min-width: 85px;

        &:focus {
            border-color: $msv-gray-940;
            outline: none;
        }

    }

    &__list {
        background-color: var(--ms-site-picker-desktop-list-bg-color);
        box-shadow: $msv-depth4;
        border: $msv-gray-930;
        display: block;
        list-style-type: none;
        padding-left: 0;
        margin: $site-picker-list-margin;
        top: 44px;
        left: -1px;
        width: 100%;

        @media (min-width: $msv-breakpoint-lg) {
            position: absolute;
            width: auto;
        }

        &__item {
            cursor: pointer;

            &__link {
                color: var(--ms-site-picker-font-color);
                flex-direction: row-reverse;
                justify-content: flex-end;
                padding-bottom: $site-picker-list-item-padding-bottom;
                padding-left: $site-picker-list-item-padding-left;
                padding-top: $site-picker-list-item-padding-top;
                padding-right: $site-picker-list-item-padding-right;
                border: 1px dashed transparent;
                align-items: flex-end;
                white-space: nowrap;
                width: 100%;

                &:focus {
                    border-color: var(--ms-site-picker-gray-500);
                    outline: none;
                }
            }

            &__img {
                padding: $site-picker-list-item-img-padding;
            }
        }
    }
}

@media (max-width: $msv-breakpoint-lg) {
    .ms-site-picker {
        margin-left: 0;
    }

    .ms-site-picker__drawer {
        .drawer__button {
            background-color: var(--ms-site-picker-bg-color);
            border: none;
            color: var(--ms-site-picker-font-color);
            cursor: pointer;
            width: 100%;
            font-size: $msv-font-size-m;
            padding-left: $site-picker-list-item-padding-left;
            padding-right: $site-picker-list-item-padding-right;
            height: $site-picker-height;
            outline-offset: -1px;

            .drawer__buttontext {
                float: left;
            }

            .ms-site-picker__drawer-close {
                position: absolute;
                right: 10px;
            }

            .ms-site-picker__drawer-open {
                position: absolute;
                right: 10px;
            }
        }

        &> :is(.collapse, .collapsing) {
            position: absolute;
        }

        .ms-site-picker__list__item__link {
            flex-direction: row-reverse;
            justify-content: flex-end;
            background-color: var(--ms-site-picker-mobile-list-bg-color);
            color: var(--ms-site-picker-font-color);
            height: $site-picker-height;
            font-size: $msv-font-size-m;
            padding-bottom: $site-picker-list-item-padding-bottom;
            padding-left: 20px;
            padding-right: $site-picker-list-item-padding-right;
            align-items: flex-end;
            width: 100%;

            &:focus {
                border: 1px dashed var(--ms-site-picker-gray-500);
                outline: none;
            }

            .ms-site-picker__list__item__text {
                margin: auto 0;
            }
        }
    }
}