@import '../00-settings/fonts.scss';
@import '../00-settings/spacings.scss';
@import "../00-settings/responsive-breakpoints.scss";

$nos-subscription-details-loading-icon-dimension: 20px;

.nos-subscription-details {
	display: flex;
	justify-content: space-evenly;
	flex-direction: column;

	&__subscription-detail-item {
		@include spacer-y-m();
	}

	&__modal-footer {
		justify-content: flex-end;

		.ms-button__primary-dark {
			margin-bottom: $spacing-m;

			@media screen and (min-width: $msv-breakpoint-md) {
				margin-bottom: 0;
				margin-right: $spacing-m;
			}
		}
	}

	h1 {
		@include container-heading-h1();
	}

	&__header-padding {
		@include spacer-y-s();
	}

	&__header-padding-m {
		@include spacer-y-m();
	}

	&__loading {
		display: flex;
		justify-content: center;

		&::before {
			content: '';
			margin-right: $spacing-s;
			border-radius: 50%;
			border-width: 1.5px;
			border-style: solid;
			border-color: $msv-raisin $msv-white $msv-white;
			border-image: initial;
			animation: spin 1s steps(8) infinite;
			animation-timing-function: cubic-bezier(0.53, 0.21, 0.29, 0.67);
			width: $nos-subscription-details-loading-icon-dimension;
			height: $nos-subscription-details-loading-icon-dimension;
		}
	}

	&__edit-button, &__pay-button {
		@include button-link($color: $msv-gray-20);
		@include link-regular-m();
		border-radius: 0;
		padding: $spacing-m;
		margin: -$spacing-m 0;
	}

	&__customer-reference-input {
		@include form-input-box;
	}

	&__save-button {
		@include primary-button-dark;
		margin-right: $spacing-m;
	}

	&__cancel-button {
		@include secondary-button-dark;
	}
}
