@import "../00-settings/colors.scss";
@import "../00-settings/icons.scss";
@import "../00-settings/spacings.scss";
@import "../01-tools/mixins.scss";
@import "../02-generic/form.scss";

// Alert borrowed from Bootstrap. We´re not using the original component because it comes with too much overhead/complexity
// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.
@each $color, $value in $theme-colors {
    .ms-alert-#{$color} {
        background: $value;
        color: $msv-white;
    }
}

.ms-alert {
    @include alert();
    @include add-icon($msv-times-circle, $icon-weight: $msv-outline-icon-weight); // Default icon

    a {
        color: inherit;
    }

    // Some customizations for special variants
    &-warning {
        color: $msv-text-color;
        @include add-icon($msv-question-circle, $icon-weight: $msv-outline-icon-weight); // Default icon
    }

    &-danger {
        @include add-icon($msv-Error, $icon-weight: $msv-outline-icon-weight);
    }

    &-success {
        @include add-icon($msv-Checkbox-Circle-Checked, $icon-weight: $msv-outline-icon-weight);
    }
}
