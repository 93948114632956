// Company Brand Colors
$msv-portland: #ff562b;
$msv-portland-10: #ffeeea;
$msv-portland-20: #ffcbbd;
$msv-portland-30: #ffb19d;
$msv-portland-40: #ff8e71;
$msv-portland-50: #ff7855;
$msv-portland-60: #ff562b;
$msv-portland-70: #e84e27;
$msv-portland-80: #b53d1f;
$msv-portland-90: #8c2f18;
$msv-portland-hover: #e84e27;
 
$msv-independent: #4c4b63;
$msv-independent-10: #ededef;
$msv-independent-20: #c8c7cf;
$msv-independent-30: #adacb7;
$msv-independent-40: #878696;
$msv-independent-50: #706f82;
$msv-independent-60: #4c4b63;
$msv-independent-70: #45445a;
$msv-independent-80: #363546;
$msv-independent-90: #2a2936;
 
$msv-raisin: #272838;
$msv-raisin-10: #e9eaeb;
$msv-raisin-20: #bcbcc1;
$msv-raisin-30: #9c9ca3;
$msv-raisin-40: #6e6f7a;
$msv-raisin-50: #525360;
$msv-raisin-60: #272838;
$msv-raisin-70: #232433;
$msv-raisin-80: #1c1c28;
$msv-raisin-90: #15161f;
$msv-raisin-hover: #000;
 
// TecDoc - Product Brand Colors
$msv-blue: #004fff;
$msv-blue-10: #e6edff;
$msv-blue-20: #b0c8ff;
$msv-blue-30: #8aaeff;
$msv-blue-40: #5489ff;
$msv-blue-50: #3372ff;
$msv-blue-60: #004fff;
$msv-blue-70: #1264a0;   
$msv-blue-80: #0038b5;
$msv-blue-90: #002b8c;
$msv-blue-100: blue; // TODO: not needed
 
// TecRMI - Product Brand Colors
$msv-emerald: #3ddc84;
$msv-emerald-10: #ecfcf3;
$msv-emerald-20: #c3f4d9;
$msv-emerald-30: #a6efc6;
$msv-emerald-40: #7de8ad;
$msv-emerald-50: #64e39d;
$msv-emerald-60: #3ddc84;
$msv-emerald-70: #38c878;
$msv-emerald-80: #2b9c5e;
$msv-emerald-90: #227949;
 
// TecCom - Product Brand Colors
$msv-salsa: #ff0800;
$msv-salsa-10: #ffe6e6;
$msv-salsa-20: #ffb2b0;
$msv-salsa-30: #ff8d8a;
$msv-salsa-40: #ff5a54;
$msv-salsa-50: #ff3933;
$msv-salsa-60: #ff0800;
$msv-salsa-70: #e80700;
$msv-salsa-80: #b50600;
$msv-salsa-90: #8c0400;
 
// TecFleet - Product Brand Colors
$msv-razzle: #ff2ecc;
$msv-razzle-10: #ffeafa;
$msv-razzle-20: #ffbeef; 
$msv-razzle-30: #ff9fe8; 
$msv-razzle-40: #ff73dd; 
$msv-razzle-50: #ff58d6; 
$msv-razzle-60: #ff2ecc; 
$msv-razzle-70: #e82aba; 
$msv-razzle-80: #b52191; 
$msv-razzle-90: #8c1970; 
 
// Neutral colors
$msv-black: #000;
$msv-dark-blue: #4c4b63;
$msv-darker-blue: #2b2c3a;
$msv-white: #fff;
$msv-yellow: #ffe01a;

// Semantic colors
$msv-yellow: #ffe01a;
$msv-yellow-10: #fffce8;
$msv-yellow-20: #fff5b8;
$msv-red: #ff0800;
$msv-red-10: #ffe6e6;
$msv-red-20: #ffb2b0;
$msv-green: #107c10;
$msv-green-10: #e7f2e7;
$msv-green-20: #b5d6b5;
$msv-green-30: #c8f1d4;
$msv-green-40: #3ff23f;
$msv-black-10: #333333;
$msv-brown: #600000;
$msv-pink: #ffe7e7;
 
$msv-transparent: transparent;
 
$msv-bg-light-grey: #f5f5f5;
 
$msv-gray-10: #100e11;
$msv-gray-20: #2c262d;
$msv-gray-30: #463d48;
$msv-gray-40: #595959;
$msv-gray-50: #c4c4c4;
$msv-gray-60: #161316;
$msv-gray-70: #201c21;
$msv-gray-75: #bfbfbf;
$msv-gray-80: #362f37;
$msv-gray-100: #f8f7f6;
$msv-gray-120: #6e726e;
$msv-gray-130: #817979;
$msv-gray-140: #949494;
$msv-gray-160: #323130;
$msv-gray-200: #868686;
$msv-gray-210: #e8e8e8;
$msv-gray-300: #d1d1d1;
$msv-gray-400: #6b727a;
$msv-gray-500: #616365;
$msv-gray-800: #8f8f8f;
$msv-gray-900: #323130;
$msv-gray-910: #2d2d2d;
$msv-gray-920: #1d1d1d;
$msv-gray-930: #ededed;
$msv-gray-940: #b6bcc1;
 
$msv-white-10: #ebebeb;
$msv-white-20: #f5f5f5;
 
$msv-primary: $msv-raisin;
$msv-primary-hover: $msv-raisin-80;
$msv-primary-pressed: $msv-raisin-60;
$msv-secondary: $msv-independent;
$msv-secondary-hover: $msv-white-20;
$msv-text-color: $msv-independent;
$msv-error-color: $msv-red-10;

// Functional colors
$msv-success: $msv-green;
$msv-info: $msv-blue-70;
$msv-warning: $msv-yellow;
$msv-danger: $msv-red;
$msv-light: $msv-gray-50;
$msv-dark: $msv-gray-20;

// Text link color
$msv-link-color: $msv-portland;
$msv-link-color-hover: #de3f16;
$msv-link-color-active: #de3f16;
$msv-link-color-focus: #de3f16;
$msv-link-color-visited: #de3f16;

$msv-divider-color: $msv-portland;
 
// Text color
$msv-font-primary-color: $msv-darker-blue;
$msv-font-secondary-color: $msv-white;
 
// Accent colors
$msv-accent-brand-color: $msv-portland;
$msv-accent-brand-alt-color: $msv-portland;
 
// Stroke colors
$msv-stroke-line: $msv-gray-50;
 
// Events
$msv-button-primary-rest-color: $msv-primary;
$msv-button-primary-hover-color: $msv-primary-hover;
$msv-button-primary-pressed-color: $msv-gray-60;
$msv-button-primary-disabled-color: $msv-gray-50;
 
$msv-button-secondary-rest-color: $msv-white;
$msv-button-secondary-hover-color: $msv-white-20;
$msv-button-secondary-pressed-color: $msv-gray-10;
$msv-button-secondary-disabled-color: $msv-white;