@import "../00-settings/colors";
@import "../00-settings/fonts";
@import "../00-settings/responsive-breakpoints";
@import "../01-tools/bootstrap/variables";

$msv-btn-border-radius: $border-radius;
$msv-btn-vertical-padding: 9px;
$msv-btn-horizontal-padding: 15px;
$msv-toggle-button-font-size: 36px;
$msv-toggle-button-padding: 0 5px;
$msv-btn-height: 44px;

$msv-link-height-s: 20px;
$msv-link-height-m: 24px;
$msv-link-height-l: 44px;

$msv-link-text-decoration-bottom-s: -8px;
$msv-link-text-decoration-bottom-m: -8px;
$msv-link-text-decoration-bottom-l: -20px;
$msv-link-text-decoration-bottom-hover-s: -4px;
$msv-link-text-decoration-bottom-hover-m: -4px;
$msv-link-text-decoration-bottom-hover-l: -12px;
$msv-link-text-decoration-height: 4px;

// TODO
$msv-card-button-height: 48px;
$msv-card-button-width: 48px;

@mixin button-link($color: $msv-link-color) {
    border-color: transparent;
    background-color: transparent;
    color: $color;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

@mixin button-icon {
    width: 48px;
    height: 48px;
}

@mixin feature-link-light-s() {
    @include feature-link-s();
    @include feature-link-text-decoration-light();
}

@mixin feature-link-light-m() {
    @include feature-link-m();
    @include feature-link-text-decoration-light();
}

@mixin feature-link-light-l() {
    @include feature-link-l();
    @include feature-link-text-decoration-light();
}

@mixin feature-link-dark-s() {
    @include feature-link-s();
    @include feature-link-text-decoration-dark();
}

@mixin feature-link-dark-m() {
    @include feature-link-m();
    @include feature-link-text-decoration-dark();
}

@mixin feature-link-dark-l() {
    @include feature-link-l();
    @include feature-link-text-decoration-dark();
}

@mixin feature-link-s() {
    @include msv-roboto-bold(
        $font-weight:$msv-font-weight-700,
        $font-style: $msv-font-style-normal,
        $font-size: $msv-font-size-s,
        $line-height: $msv-line-height-s);
    @include feature-link-underline-decoration(
        $bottom: $msv-link-text-decoration-bottom-s,
        $bottomHover: $msv-link-text-decoration-bottom-hover-s);
    min-height: $msv-link-height-s;
}

@mixin feature-link-m() {
    @include msv-roboto-bold(
        $font-weight:$msv-font-weight-700,
        $font-style: $msv-font-style-normal,
        $font-size: $msv-font-size-ml,
        $line-height: $msv-line-height-m);
    @include feature-link-underline-decoration(
        $bottom: $msv-link-text-decoration-bottom-m,
        $bottomHover: $msv-link-text-decoration-bottom-hover-m);
    min-height: $msv-link-height-m;
}

@mixin feature-link-l() {
    @include msv-roboto-bold(
        $font-weight:$msv-font-weight-700,
        $font-style: $msv-font-style-normal,
        $font-size: $msv-font-size-xl,
        $line-height: $msv-line-height-xl);
    @include feature-link-underline-decoration(
        $bottom: $msv-link-text-decoration-bottom-l,
        $bottomHover: $msv-link-text-decoration-bottom-hover-l);
    min-height: $msv-link-height-l;
}

@mixin feature-link-text-decoration-light() {
    color: var(--msv-feature-link-light-font-color);

    &:disabled,
    &[disabled] {
        color: var(--msv-feature-link-light-disabled-font-color);
    }

    &::before {
        background-color: var(--msv-feature-link-light-underline-bg-color);
    }

    &:focus {
        border: 1px solid var(--msv-feature-link-light-border-color);
        box-sizing: border-box;
        outline: none;

        &::before {
            background-color: var(--msv-feature-link-light-focus-underline-bg-color);
        }
    }

    &:hover {
        &::before {
            background-color: var(--msv-feature-link-light-hover-underline-bg-color);
        }
    }

    &:active::before {
        background-color: var(--msv-feature-link-light-active-underline-bg-color);
    }

    &:disabled::before,
    &[disabled]::before {
        background-color: var(--msv-feature-link-light-disabled-underline-bg-color);
    }
}

@mixin feature-link-text-decoration-dark() {
    color: var(--msv-feature-link-dark-font-color);

    &:disabled,
    &[disabled] {
        color: var(--msv-feature-link-dark-disabled-font-color);
    }

    &::before {
        background-color: var(--msv-feature-link-dark-underline-bg-color);
    }

    &:focus {
        border: 1px solid var(--msv-feature-link-dark-border-color);
        box-sizing: border-box;
        outline: none;

        &::before {
            background-color: var(--msv-feature-link-dark-focus-underline-bg-color);
        }
    }

    &:hover {
        &::before {
            background-color: var(--msv-feature-link-dark-hover-underline-bg-color);
        }
    }

    &:active::before {
        background-color: var(--msv-feature-link-dark-active-underline-bg-color);
    }

    &:disabled::before,
    &[disabled]::before {
        background-color: var(--msv-feature-link-dark-disabled-underline-bg-color);
    }
}

@mixin feature-link-underline-decoration($bottom: $msv-link-text-decoration-bottom-m,
$bottomHover: $msv-link-text-decoration-bottom-hover-m,
$height: $msv-link-text-decoration-height) {
    background-color: transparent;
    position: relative;
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: none;
    }

    &:disabled,
    &[disabled] {
        cursor: default;
    }

    &::before {
        bottom: $bottom;
        content: "";
        height: $height;
        left: 0;
        position: absolute;
        transform: scaleX(1);
        transition: all 0.2s ease-in-out;
        visibility: visible;
        width: 100%;
    }

    &:hover::before {
        bottom: $bottomHover;
        transform: scaleX(1);
        visibility: visible;
    }

    &:active::before {
        bottom: $bottomHover;
    }

    &:focus::before {
        bottom: $bottomHover;
    }
}

@mixin link-regular-decoration {
    color: $msv-link-color;
    text-decoration: underline;

    &:hover {
        color: $msv-link-color-hover;
    }

    &:focus {
        color: $msv-link-color-focus;
    }

    &:active {
        color: $msv-link-color-active;
    }
}

@mixin link-regular-s() {
    @include link-regular-decoration();
}

@mixin link-regular-m() {
    @include link-regular-decoration();
}

// TODO: Remove/replace?
@mixin card-button-with-icon-only() {
    background-color: $msv-portland;
    color: $msv-white;
    height: $msv-card-button-height;
    width: $msv-card-button-width;
    font-size: 0;
    opacity: 0.5;
    outline: none;
    border: none;

    &:hover {
        opacity: 0.8;
    }

    &:active {
        opacity: 0.5;
    }

    &:focus {
        border: 1px solid $msv-portland;
        box-sizing: border-box;
        opacity: 0.8;
    }

    &:disabled,
    &[disabled] {
        opacity: 0.1;
    }

    &::after,
    &::before {
        font-size: $msv-icon-size-m;
        margin: auto;
        vertical-align: middle;
    }
}

@mixin button-common-primary-dark {
    color: $msv-white;
    background-color: $msv-portland;
    font-family: $msv-font-family-roboto-regular;
    font-size: $msv-font-size-m;
    padding: $msv-btn-vertical-padding $msv-btn-horizontal-padding;

    // Set color for states explicitly mainly if mixin is used for <a> elements
    &:hover,
    &:focus,
    &:active {
        color: $msv-white;
    }
}

@mixin button-common-primary-light {
    color: $msv-portland;
    background-color: transparent;
    border-width: 2px;
    font-family: $msv-font-family-roboto-regular;
    font-size: $msv-font-size-m;
    border: 1px solid $msv-portland;

    // Set color for states explicitly mainly if mixin is used for <a> elements
    &:hover,
    &:focus,
    &:active {
        color: $msv-portland;
    }
}

@mixin button-common-secondary-dark {
    color: $msv-white;
    background-color: $msv-raisin;
    font-family: $msv-font-family-roboto-regular;
    font-size: $msv-font-size-m;

    // Set color for states explicitly mainly if mixin is used for <a> elements
    &:hover,
    &:focus,
    &:active {
        color: $msv-white;
    }
}

@mixin button-common-secondary-light {
    color: $msv-raisin;
    background-color: $msv-white;
    font-family: $msv-font-family-roboto-regular;
    font-size: $msv-font-size-m;
    border: 1px solid $msv-raisin;

    // Set color for states explicitly mainly if mixin is used for <a> elements
    &:hover,
    &:focus,
    &:active {
        color: $msv-raisin;
    }
}

@mixin button-common-decoration {
    border-radius: $msv-btn-border-radius;
    padding: $msv-btn-vertical-padding $msv-btn-horizontal-padding;
    cursor: pointer;
    display: inline-block;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    outline: none;
    overflow: hidden;
    text-align: center;
    border: none;
    height: $msv-btn-height;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }

    &:disabled,
    &[disabled] {
        box-shadow: none;
    }
}

// Button focus decoration

// TODO: needed?
@mixin button-focus-decoration {
    content: " ";
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    border-radius: $msv-btn-border-radius;
}

// Final mixins for include in components
@mixin primary-button-dark() {
    @include button-common-decoration();
    @include button-common-primary-dark();

    &:hover,
    &:focus,
    &:active {
        box-shadow: $msv-depth8;
    }

    &:disabled,
    &[disabled] {
        background-color: var(--msv-primary-btn-dark-disabled-bg-color);
        color: var(--msv-primary-btn-dark-disabled-font-color);
    }
}

@mixin primary-button-light() {
    @include button-common-decoration();
    @include button-common-primary-light();

    &:hover,
    &:focus,
    &:active {
        box-shadow: $msv-depth8;
    }

    &:disabled,
    &[disabled] {
        background-color: var(--msv-primary-btn-light-disabled-bg-color);
        color: var(--msv-primary-btn-light-font-color);
    }
}

@mixin secondary-button-dark() {
    @include button-common-decoration();
    @include button-common-secondary-dark();

    &:hover,
    &:focus,
    &:active {
        box-shadow: $msv-depth8;
    }

    &:disabled,
    &[disabled] {
        background-color: var(--msv-secondary-btn-dark-disabled-bg-color);
        color: var(--msv-secondary-btn-dark-disabled-font-color);
    }
}

@mixin secondary-button-light() {
    @include button-common-decoration();
    @include button-common-secondary-light();

    &:hover,
    &:focus,
    &:active {
        box-shadow: $msv-depth8;
    }

    &:disabled,
    &[disabled] {
        background-color: var(--msv-secondary-btn-light-disabled-bg-color);
        color: var(--msv-secondary-btn-light-font-color);
    }
}

@mixin icon-button-decoration() {
    align-items: center;
    border: none;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    letter-spacing: 0.1em;
    overflow: hidden;
    outline: none;
    padding: $msv-btn-vertical-padding $msv-btn-horizontal-padding;
    text-align: center;
    text-transform: uppercase;
}

@mixin icon-button-light() {
    @include icon-button-decoration();
    color: var(--msv-icon-button-light-font-color);
    background: var(--msv-icon-button-light-bg-color);

    &:focus {
        border: 1px solid var(--msv-icon-button-light-focus-border-color);
        box-sizing: border-box;
        outline: none;
    }

    &:disabled,
    &[disabled] {
        color: $msv-gray-50;
    }
}

@mixin icon-button-dark() {
    @include icon-button-decoration();
    color: var(--msv-icon-button-dark-font-color);
    background: var(--msv-icon-button-dark-bg-color);

    &:focus {
        border: 1px solid var(--msv-icon-button-dark-focus-border-color);
        box-sizing: border-box;
        outline: none;
    }

    &:disabled,
    &[disabled] {
        color: $msv-gray-40;
    }
}

@mixin toggle-button-decoration() {
    font-size: $msv-toggle-button-font-size;
    background-color: transparent;
    padding: $msv-toggle-button-padding;
    border: none;
    vertical-align: text-bottom;

    &:focus {
        outline: none;
        border: 1px solid var(--msv-toggle-button-focus-border-color);
        border-radius: 50px;
    }

    &:disabled,
    &[disabled] {
        color: var(--msv-toggle-button-disabled-color);
    }
}

@mixin toggle-button-on() {
    @include add-icon($msv-ToggleRight);
    @include toggle-button-decoration();
    color: var(--msv-toggle-button-color);
}

@mixin toggle-button-off() {
    @include add-icon($msv-ToggleRight);
    @include toggle-button-decoration();

    &::before {
        transform: scale(-1, 1);
    }
}
