@import "../00-settings/responsive-breakpoints";

$msv-layout-padding-x-s: 16px;
$msv-layout-padding-x-m: 40px;
$msv-layout-padding-x-l: 80px;
$msv-layout-padding-x-xl: 120px;

$msv-layout-padding-y-s: 16px;
$msv-layout-padding-y-m: 40px;
$msv-layout-padding-y-l: 64px;
$msv-layout-padding-y-xl: 80px;

$msv-layout-page-clamp-width: $msv-breakpoint-xl;
$msv-layout-container-width: $msv-breakpoint-xl;

// Container level padding
$msv-layout-container-padding-xs: 15px;
$msv-layout-container-padding-sm: 15px;
$msv-layout-container-padding-md: 30px;
$msv-layout-container-padding-lg: 45px;
$msv-layout-container-padding-xl: 60px;

// Single column width for desktop (s), tablet (m) and mobile (l).
$msv-column-width-s: 8.333%;
$msv-column-width-m: 12.5%;
$msv-column-width-l: 25%;

// Limit the max width of an element with margin on either side of the element

@mixin msv-layout-page-clamp {
    max-width: $msv-layout-page-clamp-width;
    margin: auto;
}

// Maximum width of the "normal" content container. Used to limit the main website content

@mixin msv-layout-container {
    padding-left: $msv-layout-container-padding-xs;
    padding-right: $msv-layout-container-padding-xs;
    margin: 0 auto;
    max-width: $msv-container-min-width-xs;

    @media screen and (min-width: $msv-breakpoint-sm) {
        padding-left: $msv-layout-container-padding-sm;
        padding-right: $msv-layout-container-padding-sm;
    }

    @media screen and (min-width: $msv-breakpoint-md) {
        padding-left: $msv-layout-container-padding-md;
        padding-right: $msv-layout-container-padding-md;
    }

    @media screen and (min-width: $msv-breakpoint-lg) {
        padding-left: $msv-layout-container-padding-lg;
        padding-right: $msv-layout-container-padding-lg;
    }

    @media screen and (min-width: $msv-breakpoint-xl) {
        padding-left: $msv-layout-container-padding-xl;
        padding-right: $msv-layout-container-padding-xl;
        max-width: $msv-container-min-width-xl;
    }
}

// Prevent content from touch the edge of the screen with padding on either side of the element

@mixin msv-layout-page-side-padding {
    padding-left: $msv-layout-padding-x-s;
    padding-right: $msv-layout-padding-x-s;

    @media screen and (min-width: $msv-breakpoint-md) {
        padding-left: $msv-layout-padding-x-m;
        padding-right: $msv-layout-padding-x-m;
    }

    @media screen and (min-width: $msv-breakpoint-lg) {
        padding-left: $msv-layout-padding-x-l;
        padding-right: $msv-layout-padding-x-l;
    }

    @media screen and (min-width: $msv-breakpoint-xl) {
        padding-left: $msv-layout-padding-x-xl;
        padding-right: $msv-layout-padding-x-xl;
    }
}

@mixin msv-layout-content-block-padding {
    padding: $msv-layout-padding-y-m $msv-layout-padding-x-m;

    @media screen and (min-width: $msv-breakpoint-md) {
        padding: $msv-layout-padding-y-m $msv-layout-padding-x-m;
    }

    @media screen and (min-width: $msv-breakpoint-lg) {
        padding: $msv-layout-padding-y-l $msv-layout-padding-x-l;
    }

    @media screen and (min-width: $msv-breakpoint-xl) {
        padding: $msv-layout-padding-y-xl $msv-layout-padding-x-xl;
    }
}

@mixin msv-layout-content-block-padding-y {
    padding-top: $msv-layout-padding-y-s;
    padding-bottom: $msv-layout-padding-y-s;

    @media screen and (min-width: $msv-breakpoint-md) {
        padding-top: $msv-layout-padding-y-m;
        padding-bottom: $msv-layout-padding-y-m;
    }

    @media screen and (min-width: $msv-breakpoint-lg) {
        padding-top: $msv-layout-padding-y-l;
        padding-bottom: $msv-layout-padding-y-l;
    }

    @media screen and (min-width: $msv-breakpoint-xl) {
        padding-top: $msv-layout-padding-y-xl;
        padding-bottom: $msv-layout-padding-y-xl;
    }
}
