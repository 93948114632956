@import "../00-settings/fonts.scss";
$msv-font-path: "../../../webfonts";

@font-face {
    font-family: $msv-icon-font-family;
    font-style: $msv-font-style-normal;
    font-weight: $msv-outline-icon-weight;
    font-display: auto;
    src: url("#{$msv-font-path}/remixicon.woff2") format("woff2");
}

// latin-ext
@font-face {
    font-family: $msv-font-family-roboto-regular;
    font-style: $msv-font-style-normal;
    $font-weight: $msv-font-weight-400;
    font-display: swap;
    src: url("#{$msv-font-path}/Roboto-Regular-400-latin-ext.woff2") format("woff2");
    // unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

// latin-ext
@font-face {
    font-family: $msv-font-family-roboto-bold;
    font-style: $msv-font-style-normal;
    $font-weight: $msv-font-weight-700;
    font-display: swap;
    src: url("#{$msv-font-path}/Roboto-Bold-700-latin-ext.woff2") format("woff2");
    // unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: $msv-icon-font-family-custom;
    font-style: $msv-font-style-normal;
    $font-weight: $msv-font-weight-light;
    src: url("#{$msv-font-path}/fa-light-300.woff2") format("woff2");
}

// TODO: Remove including all assets and variables?
// @font-face {
//     font-family: $msv-font-family-rubik;
//     src: url("#{$msv-font-path}/Rubik-Regular.ttf") format("truetype"),
//     url("#{$msv-font-path}/Rubik-Regular.ttf") format("truetype");
// }

// @font-face {
//     font-family: $msv-font-family-rubik-regular;
//     src: url("#{$msv-font-path}/Rubik-Regular.ttf") format("truetype"),
//     url("#{$msv-font-path}/Rubik-Regular.ttf") format("truetype");
//     font-style: normal;
//     font-weight: 500;
// }

// @font-face {
//     font-family: $msv-font-family-rubik-regular;
//     src: url("#{$msv-font-path}/Rubik-Regular.ttf") format("truetype"),
//     url("#{$msv-font-path}/Rubik-Regular.ttf") format("truetype");
//     font-style: normal;
//     font-weight: 400;
// }

// @font-face {
//     font-family: $msv-font-family-rubik-bold;
//     src: url("#{$msv-font-path}/Rubik-Bold.ttf") format("truetype"),
//     url("#{$msv-font-path}/Rubik-Bold.ttf") format("truetype");
//     font-style: normal;
//     font-weight: 700;
// }

// @font-face {
//     font-family: $msv-font-family-rubik-bold;
//     src: url("#{$msv-font-path}/Rubik-Bold.ttf") format("truetype"),
//     url("#{$msv-font-path}/Rubik-Bold.ttf") format("truetype");
//     font-style: normal;
//     font-weight: 500;
// }

// @font-face {
//     font-family: $msv-font-family-rubik-medium;
//     src: url("#{$msv-font-path}/Rubik-Medium.ttf") format("truetype"),
//     url("#{$msv-font-path}/Rubik-Medium.ttf") format("truetype");
//     font-style: normal;
//     font-weight: 700;
// }

// @font-face {
//     font-family: $msv-font-family-rubik-medium;
//     src: url("#{$msv-font-path}/Rubik-Medium.ttf") format("truetype"),
//     url("#{$msv-font-path}/Rubik-Medium.ttf") format("truetype");
//     font-style: normal;
//     font-weight: 500;
// }

// @font-face {
//     font-family: $msv-font-family-beth-ellen;
//     src: url("#{$msv-font-path}/BethEllen-Regular.ttf") format("truetype"),
//     url("#{$msv-font-path}/BethEllen-Regular.ttf") format("truetype");
// }

// @font-face {
//     font-family: $msv-font-family-beth-ellen;
//     src: url("#{$msv-font-path}/BethEllen-Regular.ttf") format("truetype"),
//     url("#{$msv-font-path}/BethEllen-Regular.ttf") format("truetype");
//     font-style: normal;
//     font-weight: 400;
// }

// TODO: remove?
@font-face {
    font-family: $msv-font-family-nothing-you-could-do;
    src: url("#{$msv-font-path}/NothingYouCouldDo-Regular.ttf") format("truetype"),
    url("#{$msv-font-path}/NothingYouCouldDo-Regular.ttf") format("truetype");
}

@font-face {
    font-family: $msv-font-family-nothing-you-could-do;
    src: url("#{$msv-font-path}/NothingYouCouldDo-Regular.ttf") format("truetype"),
    url("#{$msv-font-path}/NothingYouCouldDo-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
}
