@import "../00-settings/spacings.scss";

@mixin make-container-padding($paddings: $grid-gutter-widths, $breakpoints: $grid-breakpoints) {
    @each $breakpoint, $padding in $paddings {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
            padding-left: $padding;
            padding-right: $padding;
        }
    }
}

@mixin basicTruncation {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin basic-outline-offset(
    $outline-width: 1px,
    $outline-style: dashed,
    $outline-color: $msv-black,
    $outline-offset: -1px
) {
    outline: $outline-width $outline-style $outline-color;
    outline-offset: $outline-offset;
}

@mixin font-awesome-icon($icon: "") {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: $icon;
    display: inline;
}

@mixin screen-reader() {
    /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
    position: absolute !important;
    /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
    overflow: hidden !important;
    /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
    clip: rect(1px, 1px, 1px, 1px) !important;
    /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
    width: 1px !important;
    /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
    height: 1px !important;
    /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
    border: 0 !important;
    /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
    padding: 0 !important;
    /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
    margin: 0 !important;
}

@mixin alert {
    position: relative;
    padding: $spacing-m $spacing-m $spacing-m ($spacing-m + 25px);
    margin: $spacing-m 0;

    &::before {
        font-size: $msv-font-size-ml;
        position: absolute;
        top: 18px;
        left: $spacing-m;
    }

    [dir="ltr"] & {
        + input {
            border-left: 4px solid var(--msv-form-error-font-color);
        }

        + select {
            border-left: 4px solid var(--msv-form-error-font-color);
        }

        &::before {
            margin-right: $msv-alert-icon-margin;
        }
    }

    [dir="rtl"] & {
        + input {
            border-right: 4px solid var(--msv-form-error-font-color);
        }

        + select {
            border-right: 4px solid var(--msv-form-error-font-color);
        }

        &::before {
            margin-left: $msv-alert-icon-margin;
        }
    }
}

@mixin alert-title {
    @include error-message();
    margin-bottom: $spacing-m;
    margin-top: $spacing-s;

    &::before {
        @include font-awesome-icon($alert-icon);
        margin-right: 8px;
    }
}

@mixin button-icon {
    width: 48px;
    height: 48px;
}

@mixin vfi {
    &:focus {
        border: 1px dashed $msv-white;
        outline: 1px dashed $msv-gray-20;
    }

    @supports (-webkit-touch-callout: none) {
        &:focus {
            border: none;
            outline: 0;
        }
    }
}

@mixin vfi-psuedo($selector: after, $offset: -2px, $position: relative) {
    position: $position;

    &:focus {
        outline: 1px dashed $msv-white;

        &:#{$selector} {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: transparent;
            outline: 1px dashed $msv-black;
            outline-offset: $offset;
        }
    }
}

@mixin validation($bg-color, $border, $color) {
    background-color: $bg-color;
    border: 1px solid $border;
    color: $color;
}

@mixin validation-success($bg-color: transparent, $border: transparent, $color: var(--msv-font-primary-color)) {
    @include validation($bg-color, $border, $color);
}

@mixin validation-error($bg-color: var(--msv-error-color),
$border:  var(--msv-error-color), $color: var(--msv-font-secondary-color)) {
    @include validation($bg-color, $border, $color);
}

@mixin divider($direction) {
    border-#{$direction}: 1.5px solid #{$msv-divider-color};
}

@mixin clearfix() {
    &::after {
        display: block;
        content: "";
        clear: both;
    }
}

@mixin msv-icon($icon-weight: $msv-solid-icon-weight) {
    font-family: $msv-icon-font-family;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-weight: $icon-weight;
    text-rendering: auto;
    line-height: 1;
}

// TODO: Remove
@mixin msv-roboto-regular($font-weight: 400, $font-style: normal, $font-size: 16px, $line-height: 24px) {
    font-family: $msv-font-family-roboto-regular;
    font-weight: $font-weight;
    font-style: $font-style;
    font-size: $font-size;
    line-height: $line-height;
}

@mixin msv-roboto-bold($font-weight: 700, $font-style: normal, $font-size: 16px, $line-height: 24px) {
    font-family: $msv-font-family-roboto-bold;
    font-weight: $font-weight;
    font-style: $font-style;
    font-size: $font-size;
    line-height: $line-height;
}

// TODO: Remove?
// @mixin msv-rubik-bold($font-weight: 700, $font-style: normal, $font-size: 16px, $line-height: 24px) {
//     font-family: $msv-font-family-rubik-bold;
//     font-weight: $font-weight;
//     font-style: $font-style;
//     font-size: $font-size;
//     line-height: $line-height;
// }

// @mixin msv-rubik-regular($font-weight: 400, $font-style: normal, $font-size: 16px, $line-height: 24px) {
//     font-family: $msv-font-family-rubik-regular;
//     font-weight: $font-weight;
//     font-style: $font-style;
//     font-size: $font-size;
//     line-height: $line-height;
// }

// @mixin msv-rubik-medium($font-weight: 700, $font-style: normal, $font-size: 16px, $line-height: 24px) {
//     font-family: $msv-font-family-rubik-medium;
//     font-weight: $font-weight;
//     font-style: $font-style;
//     font-size: $font-size;
//     line-height: $line-height;
// }

@mixin msv-beth-ellen($font-weight: 400, $font-style: normal, $font-size: 16px, $line-height: 24px) {
    font-family: $msv-font-family-beth-ellen;
    font-weight: $font-weight;
    font-style: $font-style;
    font-size: $font-size;
    line-height: $line-height;
}

// TODO: Needed?
@mixin msv-nothing-you-could-do($font-weight: 400, $font-style: normal, $font-size: 16px, $line-height: 24px) {
    font-family: $msv-font-family-nothing-you-could-do;
    font-weight: $font-weight;
    font-style: $font-style;
    font-size: $font-size;
    line-height: $line-height;
}

@mixin spacer-s {
    padding: $spacing-s;
}

@mixin spacer-m {
    padding: $spacing-m;
}

@mixin spacer-l {
    padding: $spacing-l;
}

@mixin spacer-xl {
    padding: $spacing-xl;
}

@mixin spacer-y-s {
    padding-top: $spacing-s;
    padding-bottom: $spacing-s;
}

@mixin spacer-y-m {
    padding-top: $spacing-m;
    padding-bottom: $spacing-m;
}

@mixin spacer-y-l {
    padding-top: $spacing-l;
    padding-bottom: $spacing-l;
}

@mixin spacer-y-xl {
    padding-top: $spacing-xl;
    padding-bottom: $spacing-xl;
}

@mixin spacer-x-s {
    padding-left: $spacing-s;
    padding-right: $spacing-s;
}

@mixin spacer-x-m {
    padding-left: $spacing-m;
    padding-right: $spacing-m;
}

@mixin spacer-x-l {
    padding-left: $spacing-l;
    padding-right: $spacing-l;
}

@mixin spacer-x-xl {
    padding-left: $spacing-xl;
    padding-right: $spacing-xl;
}