@import "../00-settings/colors.scss";
@import "../00-settings/fonts.scss";
@import "../00-settings/icons.scss";
@import "../00-settings/responsive-breakpoints.scss";
@import "../01-tools/mixins.scss";
@import "../02-generic/image.scss";
@import "../02-generic/form.scss";

$search-autoSuggest-results-item-font-size: 14px;
$search-autoSuggest-categoryResults-item-height: 40px;
$search-autoSuggest-categoryResults-item-padding: 0 24px;
$search-autoSuggest-categoryResults-title-padding: 16px 24px 8px 12px;
$search-autoSuggest-keywordResults-item-height: 40px;
$search-autoSuggest-keywordResults-item-padding: 0 12px;
$search-autoSuggest-productResults-item-font-size: 16px;
$search-autoSuggest-productResults-item-padding-right: 20px;
$search-autoSuggest-productResults-title-padding: 20px 12px 14px 12px;
$search-autoSuggest-hover-color: $msv-gray-20;
$search-autoSuggest-font-hover-background-color: $msv-gray-70;
$search-placeholder-text-padding-left: 8px;
$search-form-control-padding: 8px;
$search-form-icon-size: 24px;
$search-form-icon-margin-right: 9px;
$search-form-icon-text-size: 14px;
$search-form-icon-text-line-height: 20px;
$search-form-submitBtn-height: 28px;
$search-form-submitBtn-width: 48px;
$search-form-padding-right: 20px;
$search-form-padding-left: 5px;
$search-form-close-button-padding-right: 15px;
$search-form-close-button-padding-top: 15px;
$search-autoSuggest-loading-item-height: 48px;
$search-autoSuggest-loading-item-padding: 13px 12px 14px 0;
$search-spinner-circle-border-color: $msv-white rgba(255, 255, 255, 0.4) rgba(255, 255, 255, 0.4);

//style presets
:root {
    --msv-search-bg: var(--msv-bg-color);
    --msv-search-font-color: var(--msv-font-primary-color);
    --msv-search-form-bg: #{$msv-gray-20};
    --msv-search-form-search-btn-color: var(--msv-accent-brand-color);
    --msv-search-form-cancel-btn-color: #{$msv-gray-20};
    --msv-search-font-size: #{$msv-font-size-s};
    --msv-search-form-placeholder-color: #{$msv-gray-70};
}

.ms-search {
    z-index: 1000;

    button {
        border: 1px solid transparent;

        @include vfi();
    }

    picture {
        grid-column: 1 / 2;
        grid-row-end: span 2;
        margin-bottom: auto;
        margin-top: auto;
    }

    &__icon {
        background: transparent;
        cursor: pointer;
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        font-family: $msv-primary-font-family;
        font-size: var(--msv-header-icon-font-size);
        line-height: $search-form-icon-text-line-height;

        &::before {
            @include msv-icon();
            content: $msv-Search;
            font-size: var(--msv-header-icon-font-size);
            vertical-align: text-bottom;
        }

        &-text {
            display: none;
        }
    }

    &__form-cancelSearchNoSumbit.msc-btn {
        position: absolute;
        right: $search-form-close-button-padding-right;
        top: $search-form-close-button-padding-top;
        color: var(--msv-nav-font-color);
        background-color: transparent;
        border: 1px solid transparent;
        @include vfi();

        &::before {
            @include msv-icon();
            content: $msv-Cancel;
            font-size: var(--msv-nav-close-icon-font-size);
            position: unset;
            text-align: center;
            vertical-align: text-bottom;
        }
    }

    &__form-submitSearch {
        color: var(--msv-search-font-color);
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        margin-left: 12px;
        background: none;
        border: none;
        order: 0;
        height: 40px;

        &::before {
            @include msv-icon();
            content: $msv-Search;
            text-align: center;
            font-size: $msv-icon-size-xs;
            vertical-align: middle;
        }
    }

    &__searchForm {
        display: flex;
        padding-left: $search-form-padding-left;
        padding-right: $search-form-padding-right;
    }

    &__form-control {
        @include form-input-box();
    }

    border: none;

    &__label.bx-show {
        display: block;
    }

    &__form.bx-hide {
        display: none;
    }

    &__icon-text {
        display: none;
    }

    &__label.bx-hide {
        display: none;
    }

    &__form.bx-show {
        display: block;
        width: 100%;

        .ms-search__searchForm {
            margin: 0;
            border: none;
            height: 100%;
            padding-left: 5px;
            padding-right: 20px;
        }

        .ms-search__form-submitSearch {
            order: 0;
            align-items: center;
            background-color: var(--msv-search-form-search-btn-color);
            color: var(--msv-font-secondary-color);
            height: $search-form-submitBtn-height;
            width: $search-form-submitBtn-width;
            padding: 0;
            margin-left: 0;
            bottom: 1px;
            position: relative;


            &::before {
                @include msv-icon();
                content: $msv-Search;
                text-align: center;
                transform: scaleX(-1);
                font-size: var(--msv-header-icon-font-size);
            }
        }
    }

    &__icon {
        &::before {
            font-size: var(--msv-header-icon-font-size);
        }
    }

    &__form.bx-hide {
        display: none;
    }

    &__form.bx-show {
        z-index: 1001;
        left: 0;
        position: fixed;
        width: $msv-width-full;
        top: 0;
        background-color: var(--ms-search-autosuggest-background-color);

        .ms-search__searchForm {
            display: flex;
            justify-content: flex-end;
            padding: 0;
            clear: both;
            margin-top: 50px;
            margin-left: $spacing-m;
            margin-right: $spacing-m;

            .msc-autoSuggest__productResults-empty-content {
                background: $msv-gray-20;
                position: absolute;
                width: 100%;
                height: 100vh;
                justify-content: flex-end;
                right: 0;
                z-index: -1;

                .msc-autoSuggest__productResults-empty-no-results-image {
                    margin-top: 56px;

                    img {
                        margin: 0 auto;
                    }
                }

                .msc-autoSuggest__productResults-empty-text-content {
                    text-align: center;
                    margin-top: 28px;
                }

                .msc-autoSuggest__productResults-empty-heading {
                    @include font-heading-h3();
                    margin-bottom: $spacing-m;
                    color: $msv-white;
                }

                .msc-autoSuggest__productResults-empty-text {
                    @include font-body-regular-m();
                    color: $msv-white;
                }
            }
        }

        .ms-search__form-control__clear.msc-btn {
            @include font-body-regular-s();
            color: var(--ms-search-autosuggest-results-font-color);
            border: 1px solid $msv-raisin;
            padding: 0 2px;
            height: 44px;
            color: $msv-text-color;
            margin-left: $spacing-s;
            border-radius: 3px;
        }
    }

    &__icon {
        &::before {
            font-size: var(--msv-header-icon-font-size);
        }
    }

    .ms-search__form-submitSearch {
        display: block;
    }
}

a.msc-autoSuggest__productResults-item {
    font-size: $search-autoSuggest-productResults-item-font-size;
    width: 100%;

    .msc-autoSuggest__productResults-item-img {
        margin: 0 auto;
    }
}

.msc-autoSuggest {
    &__categoryResults-item {
        display: flex;
        font-size: $search-autoSuggest-results-item-font-size;
        height: $search-autoSuggest-categoryResults-item-height;

        a {
            @include vfi();
            display: flex;
            height: $search-autoSuggest-categoryResults-item-height;
            line-height: $search-autoSuggest-categoryResults-item-height;
            padding: $search-autoSuggest-categoryResults-item-padding;
            width: 100%;
        }

        :hover {
            background-color: $search-autoSuggest-hover-color;
        }
    }

    &__categoryResults-title {
        @include font-body-regular-s();
        color: var(--msv-font-primary-color);
        padding: $search-autoSuggest-categoryResults-title-padding;
        text-transform: none;
    }

    &__keywordResults-item {
        display: flex;
        font-size: $search-autoSuggest-results-item-font-size;
        height: $search-autoSuggest-keywordResults-item-height;

        a {
            @include vfi();
            display: flex;
            height: $search-autoSuggest-keywordResults-item-height;
            line-height: $search-autoSuggest-keywordResults-item-height;
            padding: $search-autoSuggest-keywordResults-item-padding;
            width: 100%;
        }

        :hover {
            background-color: $search-autoSuggest-hover-color;
        }
    }

    &__productResults-item {
        @include vfi();
        display: flex;

        &-price {
            grid-column: 2 / 2;
        }

        &-text {
            grid-column: 2 / 2;
        }

        :hover {
            background-color: $search-autoSuggest-font-hover-background-color;
        }
    }

    &__productResults-title {
        @include font-body-regular-s();
        padding: $search-autoSuggest-productResults-title-padding;
        color: var(--msv-font-primary-color);
        text-transform: none;
    }

    &__screen-reader {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    &__loadingResult {
        display: flex;
        font-size: var(--msv-search-font-size);
        height: $search-autoSuggest-loading-item-height;
        padding: $search-autoSuggest-loading-item-padding;
        width: 100%;
        justify-content: flex-start;

        .ms-Spinner-circle {
            border-color: $search-spinner-circle-border-color;
        }

        .ms-Spinner-label {
            text-transform: lowercase;
            color: $msv-white;
        }
    }
}
