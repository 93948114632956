@import "../00-settings/colors.scss";
@import "../00-settings/spacings.scss";
@import "../00-settings/responsive-breakpoints.scss";

$msv-icon-font-family: "Remix Icon";
$msv-icon-font-family-custom: "Font Awesome 6 Pro";
$msv-solid-icon-weight: normal;
$msv-outline-icon-weight: 400;

// Generic font families
$msv-font-family-roboto-regular: "Roboto Regular";
$msv-font-family-roboto-bold: "Roboto Bold";
$msv-primary-font-family: -apple-system, BlinkMacSystemFont, "#{$msv-font-family-roboto-regular}", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// TODO: Needed?
$msv-font-family-beth-ellen: "BethEllen";
$msv-font-family-nothing-you-could-do: "Nothing You Could Do";

// TODO: Needed?
$msv-font-family-beth-ellen: BethEllen;
$msv-font-family-nothing-you-can-do: NothingYouCouldDo;

// Generic font styles
$msv-font-style-normal: normal;

// Generic font weights
$msv-font-weight-bold: bold;
$msv-font-weight-normal: normal;
$msv-font-weight-light: 300;
$msv-font-weight-400: 400;
$msv-font-weight-700: 700;

// Generic font sizes
$msv-font-size-xs: 12px;
$msv-font-size-s: 14px;
$msv-font-size-m: 16px;
$msv-font-size-ml: 18px;
$msv-font-size-l: 24px;
$msv-font-size-xl: 36px;
$msv-font-size-xxl: 48px;

// Generic line heights
$msv-line-height-xs: 16px;
$msv-line-height-s: 20px;
$msv-line-height-m: 24px;
$msv-line-height-l: 28px;
$msv-line-height-xl: 44px;
$msv-line-height-xxl: 63px;
$msv-line-height-xxxl: 80px;

// Heading font sizes
$msv-heading-font-size-xxxl: 48px;
$msv-heading-font-size-xxl: 40px;
$msv-heading-font-size-xl: 36px;
$msv-heading-font-size-l: 32px;
$msv-heading-font-size-m: 28px;
$msv-heading-font-size-s: 24px;
$msv-heading-font-size-xs: 20px;
$msv-heading-font-size-xxs: 16px;
$msv-heading-font-size-xxxs: 14px;

// Heading line heights
$msv-heading-line-height-xxxl: 60px;
$msv-heading-line-height-xxl: 48px;
$msv-heading-line-height-xl: 44px;
$msv-heading-line-height-l: 39px;
$msv-heading-line-height-m: 34px;
$msv-heading-line-height-s: 29px;
$msv-heading-line-height-xs: 24px;
$msv-heading-line-height-xxs: 22px;
$msv-heading-line-height-xxxs: 20px;

// Body font sizes
$msv-body-font-size-xs: 12px;
$msv-body-font-size-s: 14px;
$msv-body-font-size-m: 16px;
$msv-body-font-size-l: 18px;
$msv-body-font-size-xl: 20px;
$msv-body-font-size-xxl: 24px;

// Body line heights
$msv-body-line-height-xs: 18px;
$msv-body-line-height-s: 22px;
$msv-body-line-height-m: 26px;
$msv-body-line-height-l: 28px;
$msv-body-line-height-xl: 30px;
$msv-body-line-height-xxl: 36px;

// Callout font sizes
$msv-callout-font-size-s: 16px;
$msv-callout-font-size-m: 24px;
$msv-callout-font-size-l: 32px;

// Callout line heights
$msv-callout-line-height-s: 20px;
$msv-callout-line-height-m: 28px;
$msv-callout-line-height-l: 36px;

// Generic box shadow depths

$msv-depth4: 0 0.3px 0.9px rgba(0, 0, 0, 0.1), 0 1.6px 3.6px rgba(0, 0, 0, 0.13);
$msv-depth8: 0 3px 3px rgba(0, 0, 0, 0.25);
$msv-depth16: 0 1.2px 3.6px rgba(0, 0, 0, 0.1), 0 6.4px 14.4px rgba(0, 0, 0, 0.13);
$msv-depth24: 0 4.8px 14.4px rgba(0, 0, 0, 0.18), 0 25.6px 57.6px rgba(0, 0, 0, 0.22);

// TODO
@mixin font-glyph-standard() {
    font-size: $msv-icon-size;
}

@mixin font-glyph-small() {
    font-size: $msv-icon-size-small;
}

// Typography styles for usage inside of modules
@mixin font-heading-h1() {
    font-family: $msv-font-family-roboto-bold;
    font-size: $msv-heading-font-size-m;
    line-height: $msv-heading-line-height-m;
    color: var(--msv-body-font-color);

    @media screen and (min-width: $msv-breakpoint-md) {
        font-size: $msv-heading-font-size-l;
        line-height: $msv-heading-line-height-l;
    }

    @media screen and (min-width: $msv-breakpoint-lg) {
        font-size: $msv-heading-font-size-xxl;
        line-height: $msv-heading-line-height-xxl;
    }
}

@mixin font-heading-h2() {
    font-family: $msv-font-family-roboto-bold;
    font-size: $msv-heading-font-size-s;
    line-height: $msv-heading-line-height-s;
    color: var(--msv-body-font-color);

    @media screen and (min-width: $msv-breakpoint-md) {
        font-size: $msv-heading-font-size-m;
        line-height: $msv-heading-line-height-m;
    }

    @media screen and (min-width: $msv-breakpoint-lg) {
        font-size: $msv-heading-font-size-xl;
        line-height: $msv-heading-line-height-xl;
    }
}

@mixin font-heading-h3() {
    font-family: $msv-font-family-roboto-bold;
    font-size: $msv-heading-font-size-xs;
    line-height: $msv-heading-line-height-xs;
    color: var(--msv-body-font-color);

    @media screen and (min-width: $msv-breakpoint-md) {
        font-size: $msv-heading-font-size-s;
        line-height: $msv-heading-line-height-s;
    }

    @media screen and (min-width: $msv-breakpoint-lg) {
        font-size: $msv-heading-font-size-l;
        line-height: $msv-heading-line-height-l;
    }
}

@mixin font-heading-h4() {
    font-family: $msv-font-family-roboto-regular;
    font-size: $msv-heading-font-size-xs;
    line-height: $msv-heading-line-height-xs;
    color: var(--msv-body-font-color);

    @media screen and (min-width: $msv-breakpoint-md) {
        font-size: $msv-heading-font-size-s;
        line-height: $msv-heading-line-height-s;
    }

    @media screen and (min-width: $msv-breakpoint-lg) {
        font-size: $msv-heading-font-size-m;
        line-height: $msv-heading-line-height-m;
    }
}

@mixin font-heading-h5() {
    font-family: $msv-font-family-roboto-bold;
    font-size: $msv-heading-font-size-xxs;
    line-height: $msv-heading-line-height-xxs;
    color: var(--msv-body-font-color);

    @media screen and (min-width: $msv-breakpoint-md) {
        font-size: $msv-heading-font-size-xs;
        line-height: $msv-heading-line-height-xs;
    }

    @media screen and (min-width: $msv-breakpoint-lg) {
        font-size: $msv-heading-font-size-s;
        line-height: $msv-heading-line-height-s;
    }
}

@mixin font-heading-h6() {
    font-family: $msv-font-family-roboto-regular;
    font-size: $msv-heading-font-size-xxxs;
    line-height: $msv-heading-line-height-xxxs;
    color: var(--msv-body-font-color);

    @media screen and (min-width: $msv-breakpoint-md) {
        font-size: $msv-heading-font-size-xxs;
        line-height: $msv-heading-line-height-xxs;
    }

    @media screen and (min-width: $msv-breakpoint-lg) {
        font-size: $msv-heading-font-size-xs;
        line-height: $msv-heading-line-height-s;
    }
}

@mixin font-body-regular-s() {
    font-family: $msv-font-family-roboto-regular;
    font-size: $msv-body-font-size-m;
    line-height: $msv-body-line-height-m;
    color: var(--msv-body-font-color);
}

@mixin font-body-regular-m() {
    font-family: $msv-font-family-roboto-regular;
    font-size: $msv-body-font-size-l;
    line-height: $msv-body-line-height-l;
    color: var(--msv-body-font-color);
}

@mixin font-body-regular-l() {
    font-family: $msv-font-family-roboto-regular;
    font-size: $msv-body-font-size-xl;
    line-height: $msv-body-line-height-xl;
    color: var(--msv-body-font-color);
}

// Bold variants for font-body
@mixin font-body-bold-s() {
    font-family: $msv-font-family-roboto-bold;
    font-size: $msv-body-font-size-m;
    line-height: $msv-body-line-height-m;
    color: var(--msv-body-font-color);
}

@mixin font-body-bold-m() {
    font-family: $msv-font-family-roboto-bold;
    font-size: $msv-body-font-size-l;
    line-height: $msv-body-line-height-l;
    color: var(--msv-body-font-color);
}

@mixin font-body-bold-l() {
    font-family: $msv-font-family-roboto-bold;
    font-size: $msv-body-font-size-xl;
    line-height: $msv-body-line-height-xl;
    color: var(--msv-body-font-color);
}

// Special call-outs
@mixin font-callout-s() {
    font-family: $msv-font-family-roboto-bold;
    font-size: $msv-callout-font-size-s;
    line-height: $msv-callout-line-height-s;
    color: var(--msv-body-font-color);
}

@mixin font-callout-m() {
    font-family: $msv-font-family-roboto-bold;
    font-size: $msv-callout-font-size-m;
    line-height: $msv-callout-line-height-m;
    color: var(--msv-body-font-color);
}

@mixin font-callout-l() {
    font-family: $msv-font-family-roboto-bold;
    font-size: $msv-callout-font-size-l;
    line-height: $msv-callout-line-height-l;
    color: var(--msv-body-font-color);
}

// Price mixins
@mixin font-price-actual() {
    font-family: $msv-font-family-roboto-bold;
    font-size: $msv-callout-font-size-l;
    line-height: $msv-callout-line-height-l;
    color: var(--msv-body-font-color);
}

@mixin font-price-strikethrough() {
    font-family: $msv-font-family-roboto-regular;
    font-size: $msv-body-font-size-l;
    line-height: $msv-body-line-height-l;
    text-decoration: line-through;
    color: $msv-gray-40;
}

@mixin font-form-label() {
    font-family: $msv-font-family-roboto-bold;
    font-size: $msv-font-size-s;
    line-height: $msv-line-height-s;
    color: var(--msv-body-font-color);
}

@mixin font-form-input() {
    font-family: $msv-font-family-roboto-regular;
    font-size: $msv-font-size-m;
    line-height: $msv-line-height-s;
    color: $msv-gray-75;

    &::placeholder {
        color: $msv-gray-75;
    }

    &:active,
    &:focus {
        color: $msv-primary;
    }
}

@mixin container-heading-h1() {
    @include font-heading-h1();
    margin-top: $spacing-xl;
    margin-bottom: $spacing-xl;
}

@mixin container-heading-h2() {
    @include font-heading-h2();
    margin-left: auto;
    margin-right: auto;
    margin-top: $spacing-xl;
    margin-bottom: $spacing-xl;
    text-align: center;
}

@mixin container-heading-h3() {
    @include font-heading-h3();
    margin-left: auto;
    margin-right: auto;
    margin-top: $spacing-xl;
    margin-bottom: $spacing-xl;
    text-align: center;
}

@mixin container-heading-h4() {
    @include font-heading-h4();
    margin-left: auto;
    margin-right: auto;
    margin-top: $spacing-xl;
    margin-bottom: $spacing-xl;
    text-align: center;
}

@mixin container-heading-h5() {
    @include font-heading-h5();
    margin-left: auto;
    margin-right: auto;
    margin-top: $spacing-xl;
    margin-bottom: $spacing-xl;
    text-align: center;

}

@mixin container-heading-h6() {
    @include font-heading-h6();
    margin-left: auto;
    margin-right: auto;
    margin-top: $spacing-xl;
    margin-bottom: $spacing-xl;
    text-align: center;
}

@mixin rich-text-styles() {
    // Apply heading styles based on HTML elements
    h1 {
        @include font-heading-h1();
        margin-bottom: $spacing-m;
        font-weight: inherit;
    }

    h2 {
        @include font-heading-h2();
        margin-bottom: $spacing-m;
        font-weight: inherit;
    }

    h3 {
        @include font-heading-h3();
        margin-bottom: $spacing-m;
        font-weight: inherit;
    }

    h4 {
        @include font-heading-h4();
        margin-bottom: $spacing-m;
        font-weight: inherit;
    }

    h5 {
        @include font-heading-h5();
        margin-bottom: $spacing-m;
        font-weight: inherit;
    }

    h6 {
        @include font-heading-h6();
        margin-bottom: $spacing-m;
        font-weight: inherit;
    }

    // Default text style
    p {
        @include font-body-regular-m();
    }

}
