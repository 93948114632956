@import "../00-settings/colors.scss";
@import "../00-settings/fonts.scss";
@import "../00-settings/icons.scss";
@import "../00-settings/responsive-breakpoints.scss";
@import "../01-tools/bootstrap/variables.scss";
@import "../01-tools/layout.scss";
@import "../01-tools/mixins.scss";
@import "../02-generic/image.scss";
@import "../02-generic/button.scss";

$tile-first-margin-left: 0;
$tile-last-margin-right: 0;
$tile-last-padding-bottom-mobile: 0;
$tile-link-margin-left-right: 24px;
$tile-link-margin-top: 25px;
$tile-spacer: (2 * $grid-gutter-width);

.ms-content-block {
    &[data-m-layout="tile"] {
        @include rich-text-styles();
        display: flex;
        gap: $grid-gutter-width;

        --msv-accent-brand-color: #{$msv-portland};

		// This theme is the default setting since 05-2024 (when feature was introduced). Because of existing/old content we are setting the CSS variable value/default currently without this class
		// &.theme__corporate {

		// }

        & > div {
            flex: 1 0 calc(50% - #{$grid-gutter-width});
        }

        .msc-cta__primary {
            @include primary-button-dark();
            background-color: var(--msv-accent-brand-color);
        }

        .msc-cta__secondary {
            @include primary-button-light();
            background-color: transparent;
            border-width: 2px;
            border-color: var(--msv-accent-brand-color);
            color: var(--msv-accent-brand-color);
            &:hover,
            &:focus,
            &:active {
                color: var(--msv-accent-brand-color);
            }
        }

        .ms-content-block__image {
            --msv-accent-brand-color: #{$msv-blue};
            margin: $tile-spacer 0 0;
            position: relative;

            img {
                height: auto;
            }

            &:before {
                background: var(--msv-accent-brand-color);
                right: (-1 * $tile-spacer);
                top: (-1 * $tile-spacer);
                height: (2 * $tile-spacer);
                width: (2 * $tile-spacer);
                position: absolute;
                z-index: -1;
                content: "";
            }
        }

        &.imageplacement__right .ms-content-block__image {
            &:before {
                left: (-1 * $tile-spacer);
                right: auto;
            }
        }

        &:is(.imageplacement__top, .imageplacement__bottom) {
            .ms-content-block__image {
                margin: $tile-spacer $tile-spacer 0 0;
                img {
                    width: 100%;
                }
            }
            .ms-content-block__details {
                margin-top: unset;
            }
        }

        .ms-content-block__details {
            margin-top: (2.5 * $tile-spacer);
            text-align: left;

            &__additional-content {
                display: flex;
                font-style: normal;
                font-weight: $msv-font-weight-normal;
                flex-direction: column;
                position: absolute;

                &-cta {
                    @include feature-link-m();

                    &-links {
                        &:nth-child(1) {
                            text-decoration-line: none;
                        }

                        &:nth-child(2) {
                            text-decoration-line: underline;
                            border-left: 1px solid $msv-white;
                        }
                    }
                }
            }

            .ms-content-block__cta {
                margin-top: $tile-link-margin-top;

                a[class^="msc-cta__"] {
                    margin-left: $tile-link-margin-left-right;

                    &:first-child {
                        margin-left: $tile-first-margin-left;
                    }
                }
            }
        }

        &.textplacement__left .ms-content-block__details {
            text-align: left;

            .ms-content-block__cta {
                a[class^="msc-cta__"] {
                    margin-left: $tile-link-margin-left-right;

                    &:first-child {
                        margin-left: $tile-first-margin-left;
                    }
                }
            }
        }

        &.textplacement__right .ms-content-block__details {
            text-align: right;

            .ms-content-block__cta {
                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                a[class^="msc-cta__"] {
                    margin-right: $tile-link-margin-left-right;

                    &:last-child {
                        margin-right: $tile-last-margin-right;
                    }
                }
            }
        }

        &.textplacement__center .ms-content-block__details {
            text-align: center;

            .ms-content-block__cta {
                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                a[class^="msc-cta__"] {
                    margin-left: $tile-link-margin-left-right / 2;
                    margin-right: $tile-link-margin-left-right / 2;

                    &:first-child {
                        margin-left: $tile-first-margin-left;
                    }

                    &:last-child {
                        margin-right: $tile-last-margin-right;
                    }
                }
            }
        }

        @include media-breakpoint-up(md) {
            &.imageplacement__right {
                flex-direction: row-reverse;
            }
            &.imageplacement__top {
                flex-direction: column;
            }
            &.imageplacement__bottom {
                flex-direction: column-reverse;
            }
        }
        @include media-breakpoint-down(sm) {
            flex-direction: column;
            padding-bottom: $spacing-l;
            width: 100%;

            & .ms-content-block__image {
                margin: $tile-spacer $tile-spacer 0 0;
            }
            
            &.imageplacement__right .ms-content-block__image {
                margin: $tile-spacer 0 0 $tile-spacer;
            }

            & .ms-content-block__details {
                margin-top: 0;
            }
        }
    }

	&.theme__tecdoc {
        --msv-accent-brand-color: #{$msv-blue};

        .ms-content-block__image {
            --msv-accent-brand-color: #{$msv-blue};
        }
	}

	&.theme__teccom {
        --msv-accent-brand-color: #{$msv-salsa};
        .ms-content-block__image {
            --msv-accent-brand-color: #{$msv-salsa};
        }
	}
}

.col-sm:first-child .ms-content-block[data-m-layout="tile"]:first-child,
.col-md:first-child .ms-content-block[data-m-layout="tile"]:first-child {
    margin-left: $tile-first-margin-left;
}

.col-sm:last-child .ms-content-block[data-m-layout="tile"]:last-child,
.col-md:last-child .ms-content-block[data-m-layout="tile"]:last-child {
    margin-right: $tile-last-margin-right;
}

@media (max-width: $msv-breakpoint-md) {
    &.col-sm:last-child .ms-content-block[data-m-layout="tile"]:last-child {
        padding-bottom: $tile-last-padding-bottom-mobile;
    }
}
