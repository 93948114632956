@import "../00-settings/icons.scss";
@import "../00-settings/fonts.scss";

@mixin msv-icon-custom() {
    font-style: $msv-font-weight-normal;
    font-family: $msv-icon-font-family-custom;
    font-weight: 300;
}

.ms-icon-custom::before,
[class^=ms-icon-custom]::before {
    @include msv-icon-custom();
}

@each $name, $glyph in $msv-icon-custom {
    .ms-icon-custom--#{$name}:before {
        content: $glyph;
    }
}

@mixin add-icon-custom($icon, $selector: before) {
    &:#{$selector} {
        @include msv-icon-custom();
        content: map-get($msv-icon-custom, $icon);
    }
}