@import "../00-settings/spacings.scss";
@import "../00-settings/colors.scss";
@import "../00-settings/fonts.scss";
@import "../00-settings/responsive-breakpoints.scss";
@import "../02-generic/button.scss";

.ms-country-picker {
    &__dialog {
        // Include whole styles because author can define multiple HTML elements
        @include rich-text-styles();

        .msc-modal__body {
            display: grid;
            grid-template-columns: 1fr;
    
            @media (min-width: $msv-breakpoint-sm) {
                grid-template-columns: 1fr 1fr;
            }
        }

        .msc-btn {
            @include primary-button-dark();
        }
    }

    &__country__item__link {
        padding: $spacing-s 0;
        display: block;
        text-decoration: none;
        color: $msv-text-color;

        &:hover,
        &:focus,
        &:active {
            text-decoration: underline;
            color: $msv-link-color;
        }
    }

    &__country__item__displayString {
        display: block;
        font-weight: $msv-font-weight-bold;
    }

    &__item__displaySubString {
        display: block;
    }

    &__subheading {
        @include font-callout-s();
    }
}

