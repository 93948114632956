@import "../00-settings/spacings.scss";
@import "../00-settings/responsive-breakpoints.scss";
@import '../00-settings/colors.scss';
@import '../00-settings/fonts.scss';
@import '../01-tools/mixins.scss';
@import '../01-tools/layout.scss';
@import '../01-tools/visually-hidden';

.nos-key-facts {
    background-color: $msv-independent;
    @include spacer-y-m();

    &__container {
        @include msv-layout-container();
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        flex-wrap: wrap;

        @media (min-width: $msv-breakpoint-sm) {
            flex-direction: row;
        }
    }

    &__icon {
        font-size: 24px;
        margin-bottom: 18px;
    }

    &__key-fact-item {
        @include spacer-y-m();
        @include spacer-x-s();
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: $msv-white;
        text-align: center;

        @media (min-width: $msv-breakpoint-sm) {
            flex-basis: percentage(1/3);
        }

        @media (min-width: $msv-breakpoint-lg) {
            flex-basis: 0;
        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    &__key,
    &__value {
        white-space: nowrap;
    }

    &__key {
        @include font-callout-s();
        color: $msv-white;
    }

    &__value {
        @include font-callout-l();
        color: $msv-white;
    }

    &__icon-text {
        @include visually-hidden();
    }
}