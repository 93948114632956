@import "../00-settings/colors.scss";
@import "../00-settings/fonts.scss";
@import "../00-settings/icons.scss";
@import "../00-settings/spacings.scss";
@import "../00-settings/responsive-breakpoints.scss";
@import "../01-tools/layout.scss";
@import "../02-generic/button.scss";
@import "../02-generic/image.scss";

$product-collection-carousel-padding-top: 15px;
$product-collection-margin-discount-price: 5px;
$product-collection-item-padding: $spacing-m;
$product-collection-price-line-height: 24px;
$product-collection-price-strikethrough-line-height: $msv-line-height-m;
$product-collection-product-title-margin-top: $spacing-m;
$product-collection-product-title-margin-bottom: $spacing-m;
$product-collection-product-price-margin-bottom: 16px;
$product-collection-flipper-font-size-desktop: 32px;
$product-collection-quick-view-margin: 12px 0;
$product-collection-product-dimensions-margin-bottom: 15px;
$product-collection-product-dimensions-margin-top: 15px;

//style presets
:root {
    --msv-product-collection-font-family: #{$msv-primary-font-family};

    // heading
    --msv-product-collection-heading-font-color: var(--msv-font-primary-color);
    --msv-product-collection-text-color: var(--msv-font-primary-color);
    --msv-product-collection-text-size: var(--msv-body-font-size-m);

    // rating star
    --msv-product-collection-rating-star: var(--msv-font-primary-color);
    --msv-product-collection-rating-count: #{$msv-gray-40};

    //flipper
    --msv-product-collection-flipper-bg: #{$msv-white};
    --msv-product-collection-flipper-font-color: var(--msv-font-primary-color);
    --msv-product-collection-flipper-font-size: #{$msv-font-size-m};
    --msv-product-collection-flipper-font-size-l: #{$product-collection-flipper-font-size-desktop};
    --msv-product-collection-flipper-border: #{$msv-white};
}

.ms-product-collection {
    margin: (-1 * $spacing-s);
    margin-bottom: $spacing-xl;
    display: flex;
    flex-direction: column;

    .msc-product {
        box-shadow: 0 0 0 $spacing-s inset $msv-white, 0 0 0 ($spacing-s + 1) $msv-independent-20 inset;
        padding: $product-collection-item-padding + $spacing-s;
        border: none;
        min-height: 480px;
    }

    &__item {
        display: inline-block;
        overflow: hidden;
        text-align: center;
        vertical-align: top;
        position: relative;

        .msc-product__dimensions {
            margin-top: $product-collection-product-dimensions-margin-top;
            margin-bottom: $product-collection-product-dimensions-margin-bottom;

            .msc-swatch-container {
                justify-content: center;
            }
        }

        .ms-quickView {
            .ms-quickView__button {
                @include card-button-with-icon-only();
                @include add-icon($msv-eye, before);
                cursor: pointer;
            }
        }

        .ms-product-comparison-button {
            &__icon {
                @include card-button-with-icon-only();
            }
        }

        @media (max-width: $msv-breakpoint-lg) {
            .ms-quickView, .ms-product-comparison-button__icon {
                display: block;
                position: absolute;
                right: 10px;
                top: 10px;
                margin: 0;
            }

            .ms-product-comparison-button__icon {
                top: 70px;
            }

            &:hover,
            &:focus {
                box-shadow: $msv-depth8;
                text-decoration: none;
            }
        }

        @media (min-width: $msv-breakpoint-lg) {
            .ms-quickView, .ms-product-comparison-button__icon {
                margin: $product-collection-quick-view-margin;
                display: none;
            }

            &:hover,
            &:focus {
                .msc-product {
                    box-shadow: 0 0 0 $spacing-s inset $msv-white, 0 0 0 ($spacing-s + 1) $msv-portland inset;
                }

                .ms-quickView, .ms-product-comparison-button__icon {
                    display: block;
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    margin: 0;
                }

                .ms-product-comparison-button__icon {
                    top: 70px;
                }
            }
        }

        .msc-product__image {
            @include image(100%);

            .msc-empty_image {
                &.msc_image {
                    height: 200px;
                    margin: auto;
                    width: 200px;
                }
            }

            img {
                margin: 0 auto;
            }
            width: $msv-width-full;

            .msc_image {
                object-fit: contain;
            }
        }
    }

    // Container that holds <li> items
    .msc-ss-carousel-slide {
        width: 100%;
    }

    .msc-ss-carousel {
        padding-top: $product-collection-carousel-padding-top;

        .msc-flipper {
            bottom: -120px;
            top: unset;
            display: inline-block;
        }
    }

    @media (max-width: $msv-breakpoint-lg) {
        .msc-ss-carousel .msc-flipper {
            bottom: -60px;
            height: 48px;
            width: 48px;
        }

        .msc-ss-carousel__flipper {
            right: 54%;
        }

        .msc-ss-carousel__flipper--next {
            right: 44%;
        }
    }

    @media (max-width: $msv-breakpoint-md) {
        .msc-ss-carousel .msc-flipper {
            bottom: -80px;
        }

        .msc-ss-carousel__flipper {
            right: 60%;
        }

        .msc-ss-carousel__flipper--next {
            right: 33%;
        }
    }

    @media (min-width: $msv-breakpoint-md) {
        &__item {
            width: 50%;
        }

        .msc-ss-carousel__flipper {
            right: 54%;
        }

        .msc-ss-carousel__flipper--next {
            right: 44%;
        }
    }

    @media (min-width: $msv-breakpoint-lg) {
        &__item {
            width: 25%;
        }

        .msc-ss-carousel .msc-flipper {
            font-size: var(--msv-product-collection-flipper-font-size-l);
        }

        .msc-ss-carousel__flipper {
            right: 53%;
        }

        .msc-ss-carousel__flipper--next {
            right: 44%;
        }
    }

    .msc-product {
        display: block;
        text-decoration: none;

        @media (max-width: $msv-breakpoint-md) {
            text-align: center;
        }

        .msc-product__title {
            @include font-heading-h5();
            min-height: 100px;
            margin-top: $product-collection-product-title-margin-top;
            margin-bottom: $product-collection-product-title-margin-bottom;
            white-space: normal;
        }
    }

    .msc-price {
        white-space: normal;

        &__actual {
            @include font-price-actual();
            display: inline-block;
            margin-bottom: $product-collection-product-price-margin-bottom;
        }

        &__strikethrough {
            @include font-price-strikethrough();
            margin-right: $product-collection-margin-discount-price;
        }
    }

    .msc-product__unit-of-measure {
        display: none;
    }

    .msc-product__text {
        display: none;
    }

    .msc-rating {
        &__star,
        &__count {
            color: var(--msv-product-collection-rating-star);
            border-bottom: none;
        }

        &__half-star {
            &::after {
                color: var(--msv-product-collection-rating-star);
            }
        }
    }

    .msc-rating__count {
        display: inline-flex;

        @include font-body-regular-s();
        color: var(--msv-product-collection-rating-count);
    }

    .msc-rating__count::before {
        content: "(";
    }

    .msc-rating__count::after {
        content: ")";
    }
}

.ms-product-collection[data-m-layout="oneCard"] {
    @media (min-width: $msv-breakpoint-lg) {
        .msc-ss-carousel__flipper {
            right: 60%;
        }

        .msc-ss-carousel__flipper--next {
            right: 26%;
        }

        .ms-product-collection__item,
        .ms-product-collection__item:first-child {
            width: 100%;
        }
    }
}

.ms-product-collection[data-m-layout="twoCards"] {
    @media (min-width: $msv-breakpoint-lg) {
        .msc-ss-carousel__flipper {
            right: 54%;
        }

        .msc-ss-carousel__flipper--next {
            right: 38%;
        }

        .ms-product-collection__item,
        .ms-product-collection__item:first-child {
            width: 50%;
        }
    }
}

.ms-product-collection[data-m-layout="threeCards"] {
    @media (min-width: $msv-breakpoint-lg) {
        .msc-ss-carousel__flipper {
            right: 54%;
        }

        .msc-ss-carousel__flipper--next {
            right: 43%;
        }

        .ms-product-collection__item,
        .ms-product-collection__item:first-child {
            width: 33.33%;
        }
    }
}

.col-12:not(.col-lg-9):not(.col-lg-6):not(.col-lg-3) {
    .ms-product-collection[data-m-layout="oneCard"] {
        @media (min-width: $msv-breakpoint-lg) {
            .msc-ss-carousel {
                width: 33.33%;
                margin: auto;
            }
        }
    }

    .ms-product-collection[data-m-layout="twoCards"] {
        @media (min-width: $msv-breakpoint-lg) {
            .msc-ss-carousel {
                width: 50%;
                margin: auto;
            }
        }
    }

    .ms-product-collection[data-m-layout="threeCards"] {
        @media (min-width: $msv-breakpoint-lg) {
            .msc-ss-carousel {
                width: 75%;
                margin: 0 auto;
            }
        }
    }

    .ms-product-collection[data-m-layout="mediumWidth"] {
        @media (min-width: $msv-breakpoint-lg) {
            .msc-ss-carousel {
                width: 100%;
                margin: 0 auto;
            }
        }
    }

    .ms-product-collection[data-m-layout="oneCard"],
    .ms-product-collection[data-m-layout="twoCards"],
    .ms-product-collection[data-m-layout="threeCards"] {
        @media (max-width: $msv-breakpoint-md) {
            margin-bottom: 60px;
        }
    }
}

h1.ms-product-collection__heading {
    @include container-heading-h1();
}

h2.ms-product-collection__heading {
    @include container-heading-h2();
}

h3.ms-product-collection__heading {
    @include container-heading-h3();
}

h4.ms-product-collection__heading {
    @include container-heading-h4();
}

h5.ms-product-collection__heading {
    @include container-heading-h5();
}

h6.ms-product-collection__heading {
    @include container-heading-h6();
}