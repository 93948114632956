@import "../00-settings/fonts.scss";
@import "../02-generic/button.scss";
@import "../02-generic/form.scss";

$msv-sign-up-error-icon-font-size: 20px;

//style presets
:root {
	--msv-sign-up-font-color: var(--msv-font-primary-color);

	// heading
	--msv-sign-up-heading-font-color: #{$msv-black};
}

.nos-sign-up {
	&__heading {
		@include font-heading-h2();
		margin-bottom: $spacing-l;
	}

	&__create-button {
		@include primary-button-dark();
	}

	// Apply same logic as in nos-business-sign-up
	&__cancel-button {
		display: none;
	}

	&__page-error {
		display: none;

		&[aria-hidden="false"] {
			@include alert();
			/* stylelint-disable-next-line declaration-no-important -- Override inline style inject by AAD so we need important. */
			display: flex !important;
			line-height: $msv-sign-up-error-icon-font-size;

			&::before {
				margin-right: 10px;
				font-size: $msv-sign-up-error-icon-font-size;
			}
		}
	}

	&__account-item-label {
		@include font-form-label();
		display: block;
		margin-bottom: $spacing-s;
	}

	&__account-item-input {
		@include form-input-box();
	}

	&__disclaimer {
		margin-top: $spacing-m;
		width: 100%;

		a {
			text-decoration: underline;
		}
	}
}