$msv-dialog-width: 400px;
$msv-dialog-modal-content-padding: 34px;
$msv-dialog-modal-content-body-text-padding: 4px 0;
$msv-dialog-modal-content-empty-image-padding-bottom: 10px;
$msv-dialog-modal-content-button-margin: 6px 0;
$msv-dialog-button-width: 100%;
$msv-dialog-msc-empty_image-size: 240px;
$msv-dialog-modal-block-margin-side: 22px;
$msv-dialog-modal-block-process-status-margin-bottom: 28px;
$msv-dialog-modal-image-font-size: 116px;

.msc-modal {
    .msc-add-order-template-to-cart {
        @include primary-button-dark();
        @include vfi();

        &:not(:disabled) {
            &:hover {
                cursor: pointer;
            }
        }

        .msc-modal__title {
            @include font-heading-h3();
        }

        &__dialog {
            max-width: $msv-dialog-width;

            &__image {
                margin-right: auto;
                margin-left: auto;
                margin-bottom: $msv-dialog-modal-block-margin-side;
                font-size: $msv-dialog-modal-image-font-size;
            }

            &__process-status {
                padding: $msv-dialog-modal-content-body-text-padding;
                margin-bottom: $msv-dialog-modal-block-process-status-margin-bottom;

                @include font-body-regular-s();
            }

            .msc-modal__content {
                padding: $msv-dialog-modal-content-padding;
                border: none;
            }

            &__back-to-order-template {
                @include primary-button-dark();
                margin: $msv-dialog-modal-content-button-margin;
                width: $msv-dialog-button-width;
            }

            &__body {
                @include font-body-regular-m();
                text-align: center;

                &__loading {
                    .msc-add-order-template-to-cart__dialog__image {
                        @include add-icon($msv-Spinner, after);

                        &::after {
                            -webkit-animation: spin 1s steps(8) infinite;
                            animation: spin 1s steps(8) infinite;
                        }
                    }

                    .msc-add-order-template-to-cart__dialog__process-status {
                        color: var(--msv-accent-brand-color);
                    }
                }

                &__success {
                    .msc-add-order-template-to-cart__dialog__image {
                        @include add-icon($msv-Checkbox-Checked, after);
                        color: var(-msv-success-color);
                    }

                    .msc-add-order-template-to-cart__dialog__process-status {
                        color: var(-msv-success-color);
                    }
                }

                &__failed {
                    .msc-add-order-template-to-cart__dialog__image {
                        @include add-icon($msv-Error, after);
                        color: var(--msv-error-color);
                    }

                    .msc-add-order-template-to-cart__dialog__process-status {
                        color: var(--msv-error-color);
                    }
                }
            }

            &__header {
                margin-bottom: $msv-dialog-modal-block-margin-side;

                .msc-modal__title {
                    @include font-heading-h3();

                    span {
                        &:nth-child(1) {
                            font-weight: var(--msv-font-weight-bold);
                        }
                    }
                }
            }

            // @media (min-width: $msv-breakpoint-md+1) and (max-width: $msv-breakpoint-lg) {
            //     .msc-modal__title {
            //     }
            // }

            // @media (max-width: $msv-breakpoint-md) {
            //     .msc-modal__title {
            //     }
            // }
        }
    }
}
