@import "../00-settings/colors.scss";
@import "../00-settings/fonts.scss";
@import "../00-settings/icons.scss";
@import "../00-settings/spacings.scss";

// Form elements
$msv-form-input-box-height: 44px; // Should match with button height
$msv-form-input-textbox-padding-x: 4px;
$msv-form-input-textbox-padding-y: 8px;
$msv-alert-icon-margin: 8px;

@mixin error-message() {
    @include font-body-bold-m();
    display: block;
    color: $msv-error-color;
}

@mixin form-input-label() {
    @include font-form-label();
    display: block;
    margin-bottom: $spacing-s;;
}

@mixin form-input-label-checkbox() {
    @include font-form-label();
}

@mixin form-input-label-radio() {
    @include font-form-label();
}

@mixin form-input-box() {
    @include vfi();
    @include font-form-input();
    margin-bottom: $spacing-m;
    background: $msv-white;
    border: 1px solid $msv-gray-50;
    border-radius: 3px;
    box-sizing: border-box;
    display: block;
    height: $msv-form-input-box-height;
    outline: none;
    padding: $msv-form-input-textbox-padding-x $msv-form-input-textbox-padding-y;
    width: $msv-width-full;
    -moz-appearance: textfield;

    &:focus {
        filter: drop-shadow(0 4px 8px $msv-blue-60);
        border: 1px solid $msv-blue-60;
        outline: none;
    }

    &:active {
        border: 1px solid $msv-blue-60;
        box-shadow: 0 4px 8px $msv-blue-60;
    }
}

@mixin form-input-checkbox() {
    height: 18px;
    width: 18px;
}

@mixin form-input-radio {
    height: 18px;
    width: 18px;
}

@mixin form-input-error() {
    border: 1px solid $msv-error-color;
}

@mixin form-input-error-message() {
    @include error-message();
    @include add-icon($msv-IncidentTriangle);
    margin-bottom: 4px;

    &::before {
        margin-right: 8px;
    }
}

@mixin form-input-alert-message() {
    @include font-body-regular-l();
    color: $msv-primary;
    margin-bottom: $spacing-m;
}

@mixin form-dropdown {
    @include font-form-input();
    display: block;
    width: 100%;
    border: 1px solid $msv-gray-50;
    border-radius: 3px;
    height: $msv-form-input-box-height;
    background: $msv-white;
    padding: $msv-form-input-textbox-padding-x $msv-form-input-textbox-padding-y;
    margin-bottom: $spacing-m;

    &:focus {
        filter: drop-shadow(0 4px 8px $msv-blue);
        border: 1px solid $msv-blue;
        outline: none;
    }

    &:active {
        border: 1px solid $msv-blue;
        box-shadow: 0 4px 8px $msv-blue;
    }
}
