@import "../00-settings/colors.scss";
@import "../00-settings/fonts.scss";
@import "../00-settings/spacings.scss";
@import "../00-settings/responsive-breakpoints.scss";

.nos-text-block {
    @include rich-text-styles();
    --msv-accent-brand-color: #{$msv-portland};

	// This theme is the default setting since 05-2024 (when feature was introduced). Because of existing/old content we are setting the CSS variable value/default currently without this class
	// &.theme__corporate {

	// }

    &.theme__tecdoc {
        --msv-accent-brand-color: #{$msv-blue};
    }

    &.theme__teccom {
        --msv-accent-brand-color: #{$msv-salsa};
    }

    &__button-group {
        display: flex;
        flex-direction: row;
        @media (min-width: $msv-breakpoint-md) {
            margin-top: $spacing-l;
        }
        .ms-button__primary-dark {
            background-color: var(--msv-accent-brand-color);
        }
        .ms-button__primary-light {
            border-color: var(--msv-accent-brand-color);
            color: var(--msv-accent-brand-color);
            &:hover,
            &:focus,
            &:active {
                color: var(--msv-accent-brand-color);
            }
        }
    }

    &__layout-row,
    &__layout-column {
        display: flex;
    }

    &__layout-row {
        flex-direction: row;
    }

    &__layout-column {
        flex-direction: column;
    }

    &__pictogram {
        margin-bottom: $spacing-s;
        margin-top: $spacing-m;

        @media (min-width: $msv-breakpoint-md) {
            margin-top: $spacing-xl;
        }

        .nos-text-block__layout-row & {
            margin-right: $spacing-m;
        }
    }

    &.alignment__center {
        text-align: center;

        .nos-text-block__button-group {
            justify-content: center;
        }
    }

    &.alignment__left {
        text-align: left;
    }

    &.alignment__right {
        text-align: right;

        .nos-text-block__button-group {
            justify-content: right;
        }
    }

    // s
    &.fontsize__small {
        p {
            @include font-body-regular-s();
        }
    }

    // m
    &.fontsize__medium {
        p {
            @include font-body-regular-m();
        }
    }

    // l
    &.fontsize__large {
        p {
            @include font-body-regular-l();
        }
    }

    // xl, special use-case to be align with Sitebuilder dropdown
    &.fontsize__extralarge {
        p {
            font-family: $msv-font-family-roboto-regular;
            font-size: $msv-body-font-size-xl;
            line-height: $msv-body-line-height-xl;
        }
    }

    .msc-btn {
        margin: 0 $spacing-m;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.col-sm-auto:has(> .nos-text-block) {
    margin-top: $spacing-m;
    flex: 1 0 auto;
}
