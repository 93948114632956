@import '../00-settings/colors.scss';
@import '../00-settings/fonts.scss';
@import '../00-settings/icons.scss';
@import '../00-settings/spacings.scss';
@import '../00-settings/responsive-breakpoints.scss';
@import '../01-tools/mixins.scss';
@import '../01-tools/layout.scss';
@import '../01-tools/visually-hidden.scss';

//spacing
$header-container-spacing-to-bottom-tablet: 18px;
$signin-info-spacing-left: 8px;
$heading-padding-top: 28px;
$heading-padding-bottom: 28px;
$header-height: 48px;
$header-mobile-height: 44px;
$header-icon-width: 40px;
$header-hamburger-icon-width: 21px;
$account-link-height: 35px;
$account-links-max-width: 167px;
$popover-menu-item-height: 48px;
$header-acc-info-desktop-margin-right: 26px;
$header-nav-icon-margin-right-desktop: 10px;
$header-logo-margin-right-desktop: 32px;
$header-logo-padding-mobile: 7px 0;
$header-logo-width: auto;
$header-logo-height: 42px;
$header-cart-count-margin-bottom: 20px;
$header-cart-count-padding: 0 5px 0 5px;
$header-search-margin-right-desktop: 40px;
$header-locator-margin-right-desktop: 26px;
$header-search-margin-right-tablet: 20px;
$header-container-topbar-account-info-margin-left: 0;
$msv-profile-button-text-margin-left: 10.33px;
$msv-pop-over-box-shadow-color: rgba(0, 0, 0, 0.1);
$msv-account-pop-over-box-shadow-color: rgba(0, 0, 0, 0.13);

$header-modal-mobile-hamburger-content-padding: 0;
$header-modal-mobile-hamburger-body-padding: 0;
$header-modal-mobile-hamburger-body-button-padding: 12px 8px;
$header-modal-mobile-close-button-padding-right: 12px;
$header-modal-mobile-close-button-padding-top: 10px;

//style presets
:root {
    --msv-header-bg: var(--msv-bg-color);
    --msv-header-font-color: var(--msv-font-accent-color);
    --msv-header-icon-font-size: #{$msv-icon-size-xs};
}

.msc-modal .nos-header__mobile-hamburger .msc-btn {
    display: block;
    background-color: var(--msv-header-bg);
}

.lock-opaque {
    top: 0;
    position: fixed;
    z-index: 1010;
    width: 100%;
    box-shadow: $msv-depth8;

    .nos-header__collapsible-hamburger.show {
        /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
        min-height: auto !important;
    }
}

.nos-header {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background-color: var(--msv-header-bg);
    color: var(--msv-header-font-color);

    .msc-btn {
        background-color: var(--msv-header-bg);
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &__container {
        margin: 0 auto;
        @include msv-layout-container();

        .nos-header__preferred-store-btn,
        .ms-search__icon,
        .nos-header__signin-button.msc-btn,
        .msc-wishlist-icon,
        .msc-cart-icon,
        .ms-search__icon,
        .ms-search__icon.msc-btn,
        .drawer__button.msc-btn,
        .ms-cart-icon-wrapper.msc-btn,
        .ms-site-picker__button,
        .nos-header__profile-button,
        .nos-header__nav-icon {
            @include font-body-regular-m();

            color: var(--msv-header-font-color);
            border: 1px dashed transparent;

            &:focus {
                border-color: $msv-gray-940;
                outline: none;
            }
        }

        .nos-header__account-info {
            @include font-body-regular-s();
            margin-right: $header-acc-info-desktop-margin-right;


            .nos-header__signin-button,
            .nos-header__profile-button {
                @include add-icon-custom("circleUser");

                background-color: var(--msv-header-bg);
                color: var(--msv-header-font-color);

                white-space: nowrap;

                &::before {
                    font-size: var(--msv-header-icon-font-size);
                }
            }

            &__account-link-container {
                height: $account-link-height;
            }

            a {
                align-items: center;
                display: flex;
                background-color: $msv-white;
                color: $msv-primary;
                white-space: nowrap;
                padding: 10px 20px;
            }

            .msc-popover {
                background-clip: padding-box;
                background-color: $msv-white;
                display: block;
                left: 0;
                max-width: $account-links-max-width;
                position: absolute;
                word-wrap: break-word;
                z-index: 1060;
                box-shadow: 0 0.6px 1.8px $msv-pop-over-box-shadow-color, 0 3.2px 7.2px $msv-account-pop-over-box-shadow-color;

                .msc-popover-inner {

                    .nos-signin-info__account-link-button:hover,
                    .nos-header__signout-button:hover {
                        background-color: $msv-white-20;
                    }
                }

                .msc-btn {
                    background-color: $msv-white;
                    color: $msv-primary;
                    font-weight: normal;
                    height: $popover-menu-item-height;
                }

                .msc-arrow {
                    display: none;
                }
            }

            .nos-profile-button-text,
            .nos-header__signin-button-text {
                margin-left: $msv-profile-button-text-margin-left;
                vertical-align: top;
            }
        }

        .nos-header__topbar {
            margin: 0 auto;
            align-items: center;
            display: flex;
            flex-wrap: nowrap;
            height: $header-mobile-height;
            color: $msv-raisin;

            .nos-header__nav-icon {
                background: var(--msv-header-bg);
                display: flex;
                align-items: center;
                color: var(--msv-header-font-color);
                cursor: pointer;

                &:hover {
                    text-decoration: none;
                }

                &::before {
                    font-size: var(--msv-header-icon-font-size);
                }
            }

            .nos-header__logo {
                display: block;

                a {
                    @include vfi();
                    display: block;
                }

                img {
                    width: $header-logo-width;
                    height: $header-logo-height;
                }
            }

            .nos-header__mobile-logo {
                display: none;
            }

            .nos-header__preferred-store-container {
                .nos-header__preferred-store-btn {
                    border: 1px solid transparent;
                    display: flex;
                    height: $header-height;
                    line-height: $msv-line-height-m;
                    align-items: center;
                    padding: 0;
                    cursor: pointer;

                    @include vfi();
                    @include add-icon($msv-Shop-Address);

                    &::before {
                        font-size: var(--msv-header-icon-font-size);
                        display: inline-block;
                        width: auto;
                        text-align: center;
                    }

                    .nos-header__preferred-store-text {
                        @include font-body-regular-m();
                        color: var(--msv-header-font-color);
                        white-space: nowrap;
                    }
                }
            }

            .nos-header__wishlist-desktop {
                display: none;

                &::before {
                    font-size: var(--msv-header-icon-font-size);
                }

                .msc-wishlist-icon__text {
                    display: none;
                }
            }

            .msc-cart-icon {
                display: flex;
                align-items: center;

                &:hover {
                    text-decoration: none;
                }

                &::before {
                    @include msv-icon();
                    content: $msv-shopping-bag;
                    font-size: var(--msv-header-icon-font-size);
                    vertical-align: text-bottom;
                    text-align: center;
                }

                &__count {
                    @include font-body-regular-s();
                    color: $msv-white;
                    margin-bottom: $header-cart-count-margin-bottom;
                    padding: $header-cart-count-padding;
                    background-color: $msv-portland;
                    text-align: center;

                    // Empty badges collapse automatically
                    &:empty {
                        display: none;
                    }
                }
            }

            .nos-cart-icon-wrapper {
                border: 1px solid transparent;
                padding: 0;
                cursor: pointer;

                @include vfi();
            }

            .nos-header__account-info a:hover {
                text-decoration: none;
            }
        }

        .nos-header__collapsible-hamburger {
            position: absolute;
            width: 100%;
            left: 0;
			z-index: 1100;

            &.collapsing {
                display: none;
            }

            @media (min-width: $msv-breakpoint-lg) {
                top: 100px;
            }

            @media (max-width: $msv-breakpoint-lg) {
                top: 80px;
            }

            .nos-header__mobile-hamburger-menu-links {
                display: none;
            }
        }
    }

    /* stylelint-disable no-descending-specificity -- Suppressed. */
    &.hide-header-options {

        .nos-header__nav-icon,
        .msc-cart-icon,
        .msc-wishlist-icon,
        .nos-header__account-info {
            display: none;
        }
    }

    @media (min-width: $msv-breakpoint-xs) {
        .msc-cart-icon {
            &::before {
                width: $header-icon-width / 2;
            }
        }
    }

    @media screen and (min-width: $msv-breakpoint-lg) {
        .nos-header__preferred-store-container {
            .nos-header__preferred-store-btn {
                cursor: pointer;

                &::before {
                    margin-right: $header-nav-icon-margin-right-desktop;
                }
            }
        }
    }

    @media (min-width: $msv-breakpoint-md) and (max-width: $msv-breakpoint-lg) {
        .mobile-navicon-container {
            display: none;
        }

        .nos-nav.desktop-vp {
            display: flex;
            position: relative;
            border-top: 1px solid var(--msv-header-font-color);
        }

        &__container {
            .nos-header__topbar {

                .nos-header__account-info {
                    margin-right: $header-search-margin-right-tablet;
                }

                .nos-header__preferred-store-container {
                    margin-right: 0;
                }

                .nos-header__preferred-store-btn {
                    margin-right: $header-search-margin-right-tablet;
                    cursor: pointer;

                    &::before {
                        margin-right: 0;
                    }
                }

                .nos-header__container .nos-header__topbar .nos-header__account-info {
                    margin-right: $header-search-margin-right-tablet;
                    margin-top: 0;
                }
            }
        }
    }

    @media (min-width: $msv-breakpoint-lg) {
        padding-top: $heading-padding-top;
        padding-bottom: $heading-padding-bottom;

        &__modal {
            display: none;
        }

        &__profile-button {
            color: var(--msv-header-font-color);
            font-weight: normal;
            background: var(--msv-header-bg);

            &::before {
                @include font-body-regular-m();
                width: $header-icon-width;

                @include msv-icon();
                content: $msv-signin;
                font-weight: normal;
            }

            &:focus {
                outline: none;
                border: none;
            }
        }

        &__divider {
            display: none;
        }

        .ms-search {
            justify-content: flex-end;
            flex-basis: 0;
            flex-grow: 1;
            display: flex;

            .hide {
                display: none;
            }

            .ms-search__label.bx-show {
                display: block;
            }
        }

        &__preferred-store-container {
            margin-right: $header-locator-margin-right-desktop;
        }

        .nos-header__wishlist-desktop {
            display: flex;
        }

        .nos-nav.desktop-vp {
            display: flex;
            position: relative;
            border-top: 1px solid var(--msv-header-font-color);
        }

        .mobile-navicon-container {
            display: none;
        }
    }

    // @media (max-width: $msv-breakpoint-md) {
    //     .nos-profile-button-text {
    //         display: none;
    //     }

    //     .nos-header__profile-button {
    //         color: var(--msv-header-font-color);
    //         font-weight: normal;
    //         background: var(--msv-header-bg);

    //         &::before {
    //             @include font-body-regular-m();
    //             @include msv-icon();
    //             content: $msv-signin;
    //             color: var(--msv-header-font-color);
    //         }
    //     }
    // }

    @media (max-width: $msv-breakpoint-lg) {
        padding-top: $header-container-spacing-to-bottom-tablet;
        padding-bottom: $header-container-spacing-to-bottom-tablet;

        &__container {
            .nos-header__topbar {
                .nos-header__mobile-logo {
                    display: block;

                    img {
                        width: $header-logo-width;
                        height: $header-logo-height;
                    }

                    a {
                        @include vfi();
                        padding: $header-logo-padding-mobile;
                    }
                }

                .nos-header__preferred-store-container {
                    .nos-header__preferred-store-btn {
                        cursor: pointer;

                        @include vfi();

                        &::before {
                            font-size: var(--msv-header-icon-font-size);
                            vertical-align: text-bottom;
                            text-align: center;
                        }

                        .nos-header__preferred-store-text {
                            display: none;
                        }
                    }
                }

                .nos-header__account-info {
                    margin-left: $header-container-topbar-account-info-margin-left;

                    .nos-header__signin-button {

                        .nos-header__signin-button-text {
                            display: none;
                        }
                    }
                }
            }
        }

        .ms-search {
            display: flex;
            justify-content: flex-end;
            flex-basis: 0;
            flex-grow: 1;
            border: none;

            .hide {
                display: none;
            }

            .ms-search__label.bx-show {
                display: block;

                .ms-search__icon {
                    align-items: center;
                    color: var(--msv-header-font-color);
                    font-weight: normal;

                    @media (max-width: $msv-breakpoint-lg - 1) {
                        &::before {
                            font-size: var(--msv-header-icon-font-size);
                        }
                    }

                    .ms-search__icon-text {
                        display: none;
                    }
                }
            }
        }

        .nos-profile-button-text {
            @include visually-hidden();
        }

        .nos-header__profile-button {
            color: var(--msv-header-font-color);
            font-weight: normal;
            background: var(--msv-header-bg);

            &::before {
                @include font-body-regular-m();
                color: var(--msv-header-font-color);
                width: $header-icon-width;

                @include msv-icon();
                content: $msv-signin;
            }
        }
    }

    @media (max-width: ($msv-breakpoint-md)-1) {
        &__container {
            .nos-header__topbar {
                .desktop-navicon-container {
                    display: none;
                }

                .ms-search {
                    margin-right: auto;
                }

                .nos-header__account-info {
                    margin-right: 0;
                }

                .nos-header__preferred-store-btn {
                    margin-right: 0;
                    cursor: pointer;

                    &::before {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .ms-site-picker .ms-site-picker__button,
    .nos-header__signin-button.msc-btn,
    .nos-header__nav-icon.msc-btn,
    .nos-header .msc-btn.msc-btn,
    .drawer__button.msc-btn,
    .ms-cart-icon .msc-btn,
    .ms-search__icon {
        padding: 0 7px;
        cursor: pointer;
        min-width: auto;
        min-height: 44px;
        height: auto;
        width: auto;

        @media (min-width: $msv-breakpoint-md) {
            padding: 0 10px;
        }
    }

    .drawer__button.msc-btn {
        display: grid;
        place-items: center;
    }

    .msc-btn::before,
    .ms-icon-custom::before {
        font-size: var(--msv-header-icon-font-size);
    }

    .ms-cart-icon .msc-btn {
        @include add-icon-custom("basket");
    }

    .ms-search .msc-btn.ms-search__icon {
        @include add-icon-custom("magnifyingGlass");
    }

    .nos-header__nav-icon {
        @include add-icon-custom("bars");
    }

    .nos-header__wishlist-desktop {
        @include add-icon-custom("heart");
    }

    .nos-header__topbar {
        justify-content: space-between;

        @media (min-width: $msv-breakpoint-lg) {
            justify-content: flex-end;
        }

        .nos-header__logo {
            @media (min-width: $msv-breakpoint-lg) {
                flex-grow: 1;
            }

            min-width: 150px;
        }

        .ms-site-picker {
            margin-left: initial;
            height: auto;

            .ms-site-picker__list {
                position: absolute;
                z-index: 1; // Reset stack
            }

            .collapse,
            .collapsing {
                box-shadow:
                    0 0.6px 1.8px $msv-pop-over-box-shadow-color,
                    0 3.2px 7.2px $msv-account-pop-over-box-shadow-color;
                position: absolute;
                z-index: 1;
            }

            @media (min-width: $msv-breakpoint-lg) {
                position: relative;
            }
        }

        .ms-cart-icon {
            position: relative;

            .msc-cart-icon__count {
                position: absolute;
                padding: 0 3px;
                left: 20px;
                top: 0;
            }
        }

        .nos-header__account-info {
            .nos-header__profile-button {
                display: flex;
                align-items: center;

                &::before {
                    width: auto;
                }
            }
        }

        .nav-menu-container {
            @media (max-width: ($msv-breakpoint-md)-1) {
                order: 7;
            }

            display: flex;

            &>ul {
                margin-left: auto;
                list-style: none;
                display: none;
                padding: 0;
                margin: 0;

                &>li {
                    place-items: center;
                    display: grid;

                    a,
                    .msc-btn {
                        @include font-body-bold-m();
                        @include spacer-x-m();
                        cursor: pointer;
                        display: block;
                        border: none;

                        &:hover {
                            text-decoration: underline;
                        }

                        &.open {
                            color: $msv-link-color-active;
                        }
                    }
                }
            }

            @media (min-width: ($msv-breakpoint-md)-1) {
                &>ul {
                    display: flex;
                }

                &>.msc-btn {
                    display: none;
                }
            }
        }
    }
}

.nos-header__modal {
    @media (min-width: ($msv-breakpoint-md)-1) {
        display: none;
    }

    .msc-modal {
        .nos-header__mobile-hamburger {
            background: var(--msv-header-bg);
            height: 100%;
            margin: 0;
            max-width: none;

            .msc-modal__content {
                height: 100%;
                padding: $header-modal-mobile-hamburger-content-padding;
                background: var(--msv-header-bg);
                color: var(--msv-header-font-color);

                .nos-header__mobile-hamburger-menu-body {
                    padding: $header-modal-mobile-hamburger-body-padding;

                    .msc-btn {
                        font-size: 16px;
                        height: unset;
                        line-height: 22px;
                        padding: $header-modal-mobile-hamburger-body-button-padding;
                    }
                }

                .msc-modal__header {
                    display: block;
                    height: 18px;

                    .msc-modal__close-button {
                        color: var(--msv-header-font-color);
                        right: 0;
                        margin: 0;
                        opacity: 1;
                        padding-right: $header-modal-mobile-close-button-padding-right;
                        padding-top: $header-modal-mobile-close-button-padding-top;
                        z-index: 1000;

                        &:focus {
                            outline-offset: 0;
                        }

                        &::before {
                            @include msv-icon();
                            content: $msv-Cancel;
                            font-size: var(--msv-header-icon-font-size);
                            position: unset;
                            text-align: center;
                            vertical-align: text-bottom;
                        }

                        span {
                            display: none;
                        }
                    }
                }
            }

            .nos-header__mobile-hamburger-menu-links {
                display: none;
            }
        }
    }
}

.skip-to-main {
    @include font-body-regular-s();
    color: $msv-black;
}
